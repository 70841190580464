import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/CaseStudyLayout/CaseStudyLayout.tsx";
import CaseStudyIntro from '../components/CaseStudyIntro/CaseStudyIntro';
import CaseStudyConclusion from '../components/CaseStudyConclusion/CaseStudyConclusion';
import heroImage from './heartwood/Heartwood-Hero.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CaseStudyIntro title="Heartwood" subtitle="A design system to propel Spruce Labs’s mission to define the future of brick and mortar retail." image={{
      src: heroImage,
      alt: "Heartwood hero image"
    }} content={[{
      kind: 'text',
      headline: 'The Client',
      text: "Spruce Labs's mission is to help brick and mortar retailers thrive by enabling them to create great experiences. Founded in 2016, the team created Sprucebot (or Spruce for short) at Spruce Boutique in Denver. From day one, Spruce has found success by combining technology with a human-centered approach to experience management."
    }, {
      kind: 'text',
      headline: 'My Role',
      text: "As Spruce’s principal designer, I led the design team's strategy and execution. This included product and visual design, illustration, and frontend web development."
    }, {
      kind: 'text',
      headline: 'The Challenge',
      text: "For years, Spruce had evolved without any real design direction. We had created a small and simple library of components early on, but it hadn't changed in several years. As a result, component availability became the driver of experience design. This created an experience that felt wonky and awkward. On top of that, there was internal confusion on the team due to lack of a shared language around the product."
    }, {
      kind: 'quote',
      quote: "“When you only have a hammer, everything looks like a nail.”",
      attribution: "— Maslow's Hammer"
    }, {
      kind: 'text',
      text: "My goal with Heartwood was to create a shared design language to help our team improve Spruce. I decided early on that this would be more than a component library. We needed a tool, or a set of tools, that would scale with the product."
    }]} aside={[{
      headline: 'What I did',
      items: ['Design Strategy', 'Digital Product Design', 'Visual Design', 'Frontend Development']
    }, {
      headline: 'Deliverables',
      items: ['Design Tokens', 'Component Library', 'Fractal Components', 'React Storybook Components']
    }]} mdxType="CaseStudyIntro" />
    <h2>{`Defining our Design Principles`}</h2>
    <p>{`Before getting into visual styles or components, we defined our design principles. These would serve as stimulus for all future design decisions.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABVklEQVQoz21SDXPCIAz1//9IZzerdXV38h2gEJMA1e2Wu5Q0vDzIC4eHDqgMkAfUFtC6hNYnNDa2HO0xhl13nHGRcFGwXMM51XEHDmqtyLZtGzrnqdBjSmnP+5DE2TgFEAVjCVtKkXyhDeb6Reh9wONpwuPHhOt6x2Eekvg49DxfBcOutO4HVbnpgdtoVuV2xlhxpTTmnGUnEFmAFvPtGPdQCrU2aK2TQ9iEkPtuBaSdIw09oPMgsQsRIWbSE8Q5hkiy+NiwbuSy1IuG/BkaxVQw5YIga5VbMXAQctxwW8fWJkevF8JXy0hCG7x9r7jc7qLhfy1La9TueV5wvix7u61ehhJEUJ7WdbnhafrCy3XB6XOWKf6dckoZ1/uPYJlQk96vobxNmT0EoEKQlcly3l6Efcp8cCl1vxX/j3ohlAfbNbL7g43tcdNq9ocbdi1H/j1mDuZ6AlimCT9JCoXsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c988207c31209114302a4d8fa5b402ae/e93cc/Heartwood-Design-Principles%402x.webp 300w", "/static/c988207c31209114302a4d8fa5b402ae/b0544/Heartwood-Design-Principles%402x.webp 600w", "/static/c988207c31209114302a4d8fa5b402ae/68fc1/Heartwood-Design-Principles%402x.webp 1200w", "/static/c988207c31209114302a4d8fa5b402ae/a2303/Heartwood-Design-Principles%402x.webp 1800w", "/static/c988207c31209114302a4d8fa5b402ae/4293a/Heartwood-Design-Principles%402x.webp 2400w", "/static/c988207c31209114302a4d8fa5b402ae/55654/Heartwood-Design-Principles%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c988207c31209114302a4d8fa5b402ae/aaafa/Heartwood-Design-Principles%402x.png 300w", "/static/c988207c31209114302a4d8fa5b402ae/8db2a/Heartwood-Design-Principles%402x.png 600w", "/static/c988207c31209114302a4d8fa5b402ae/6ca50/Heartwood-Design-Principles%402x.png 1200w", "/static/c988207c31209114302a4d8fa5b402ae/4d07a/Heartwood-Design-Principles%402x.png 1800w", "/static/c988207c31209114302a4d8fa5b402ae/ee4de/Heartwood-Design-Principles%402x.png 2400w", "/static/c988207c31209114302a4d8fa5b402ae/9391c/Heartwood-Design-Principles%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c988207c31209114302a4d8fa5b402ae/6ca50/Heartwood-Design-Principles%402x.png",
              "alt": "Heartwood design principles graphic",
              "title": "Heartwood’s core design principles.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Heartwood’s core design principles.`}</figcaption>{`
  `}</figure></p>
    <p>{`Working with the rest of the team, we explored the company's core values as a jumping off point. The result of this exploration was four key principles:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Humanity:`}</strong>{` Spruce exists to enhance human-to-human interaction.`}</li>
      <li parentName="ol"><strong parentName="li">{`Physicality:`}</strong>{` We are creating the digital platform for physical retail.`}</li>
      <li parentName="ol"><strong parentName="li">{`Augmentation:`}</strong>{` Technology is good at things that humans aren’t, and vice versa.`}</li>
      <li parentName="ol"><strong parentName="li">{`Flexibility:`}</strong>{` Change is the only constant.`}</li>
    </ol>
    <h2>{`System Architecture`}</h2>
    <p>{`The next step was to determine Heartwood's system architecture. The last thing we wanted was to create a pretty design system that never gets implemented. We worked with the engineering team to figure out how we could get Heartwood into place and keep it up to date.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.857142857142854%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABgklEQVQ4y42TiXKCQBBE/f+fS+KFKArIfSOnKNiZWbOW0WDcKooFdntf9wwT/IzL5XK7H49HujoxH4ZBLoHjejD3NpIkRd/3t/VyL4/J40teeDgUCIIIhmnB8wOkaYamaUnQx0434QchyqoaF7wnPJ/PRGEhIRHemGU5wiiGslrDsh1EUQLH8VDV9a99o4RMZzsuiqKE5wXI8hwpie4tB1/TBXQiXCxVVFX92vKVrkccJ0TiCirX84UYWw7DmMQP4juTy2xHCaXdiDZwTpwhH9B1HXKiXioq5gsFs7lCtLb49q/g6XQS2QVhRFnFwmbIczqED2AyFuXnv+w+CXIlp7OlaIuiLGGT9bKsREXZNo+2PeKxkC8JV6oG3w/R0TynzPgQvltEK5/v6V4S9v2AumlElrphQl1rojBcebbL2e6psd8W5Mq17ZVA2+rQtJ3IUvYit9Nbgo85cl66scd6sxUF4KpKu0w/lt9TY8tFbO/jcwZF3VCmGxgkzoKS/v6/fxzf1kztxopu+lUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/95144cb0e9891de82a6cbe771546f2f7/e93cc/System-Architecture-Sketch%402x.webp 300w", "/static/95144cb0e9891de82a6cbe771546f2f7/b0544/System-Architecture-Sketch%402x.webp 600w", "/static/95144cb0e9891de82a6cbe771546f2f7/68fc1/System-Architecture-Sketch%402x.webp 1200w", "/static/95144cb0e9891de82a6cbe771546f2f7/a2303/System-Architecture-Sketch%402x.webp 1800w", "/static/95144cb0e9891de82a6cbe771546f2f7/4293a/System-Architecture-Sketch%402x.webp 2400w", "/static/95144cb0e9891de82a6cbe771546f2f7/55654/System-Architecture-Sketch%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/95144cb0e9891de82a6cbe771546f2f7/aaafa/System-Architecture-Sketch%402x.png 300w", "/static/95144cb0e9891de82a6cbe771546f2f7/8db2a/System-Architecture-Sketch%402x.png 600w", "/static/95144cb0e9891de82a6cbe771546f2f7/6ca50/System-Architecture-Sketch%402x.png 1200w", "/static/95144cb0e9891de82a6cbe771546f2f7/4d07a/System-Architecture-Sketch%402x.png 1800w", "/static/95144cb0e9891de82a6cbe771546f2f7/ee4de/System-Architecture-Sketch%402x.png 2400w", "/static/95144cb0e9891de82a6cbe771546f2f7/9391c/System-Architecture-Sketch%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/95144cb0e9891de82a6cbe771546f2f7/6ca50/System-Architecture-Sketch%402x.png",
              "alt": "An early sketch of the Heartwood system architecture",
              "title": "An early sketch of Heartwood’s system architecture.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`An early sketch of Heartwood’s system architecture.`}</figcaption>{`
  `}</figure></p>
    <p>{`There were two key considerations at this phase. First, we wanted to enable designers to influence the product with minimal risk. Second, we wanted to avoid slowing down the engineering team's workflow.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.71428571428572%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABgUlEQVQ4y4VTy3KDMBDj//+xh/TUxjF+20Ays5WWmAGSSQ8aL7ZXSFoYSlvkHXKdt7q2WaH1dFec73QMn8i4hjzJbQxigZAa6ihX6yXm9pb0I2EqIHNVrEsyAjY0+TVefq72f8L1YD7UCevok6Rc1fL35VdKXaTNjxeyXg9ni8dLi9xgz4cEoibGOKieX1Ttn4fzJgOnVVoKqSKzjMwSEGE/a448J8pOwKawq8p9E0qW+0Nam+R6c+JCVsttWsTcvIRYJKasz5evC+pycHcYiiqDqlyoDo2csA1KyD0PMsJYp8+ji1vum2U2EczGwJYBgYctfi4hTVBYMJisqwdomQNaLa99nYMYXKhyhkVWoydR1SlzKBURVORqxyQOL/BxVch13ztw4wwS8lAJXVFyzwa/1owlxHVgvLfvHfob9msnVLX4M0YXZMIQaq6qMCFfD5UG098r3Ag79oTdMnOLz0+FuVooNrT9jOYs5i0hyXhZG5AhLVYo9Jn/ctrONIqTwj/nHTdwBaykEwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c69ef7708787a8f2cc681134447c0965/e93cc/Sys-Arch-02%402x.webp 300w", "/static/c69ef7708787a8f2cc681134447c0965/b0544/Sys-Arch-02%402x.webp 600w", "/static/c69ef7708787a8f2cc681134447c0965/68fc1/Sys-Arch-02%402x.webp 1200w", "/static/c69ef7708787a8f2cc681134447c0965/a2303/Sys-Arch-02%402x.webp 1800w", "/static/c69ef7708787a8f2cc681134447c0965/4293a/Sys-Arch-02%402x.webp 2400w", "/static/c69ef7708787a8f2cc681134447c0965/55654/Sys-Arch-02%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c69ef7708787a8f2cc681134447c0965/aaafa/Sys-Arch-02%402x.png 300w", "/static/c69ef7708787a8f2cc681134447c0965/8db2a/Sys-Arch-02%402x.png 600w", "/static/c69ef7708787a8f2cc681134447c0965/6ca50/Sys-Arch-02%402x.png 1200w", "/static/c69ef7708787a8f2cc681134447c0965/4d07a/Sys-Arch-02%402x.png 1800w", "/static/c69ef7708787a8f2cc681134447c0965/ee4de/Sys-Arch-02%402x.png 2400w", "/static/c69ef7708787a8f2cc681134447c0965/9391c/Sys-Arch-02%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c69ef7708787a8f2cc681134447c0965/6ca50/Sys-Arch-02%402x.png",
              "alt": "Diagram showing the final Heartwood system architecture",
              "title": "The final system architecture.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The final system architecture.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Shared Styles`}</h2>
    <p>{`Using the architecture as a map, the next step was to define shared visual styles. We divided our styles into six main categories: color, typography, spacing, iconography, layer, and motion.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.78571428571428%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACGklEQVQ4y3WTuY4TQRCG/V6EJEjwGEgbEiASAh4BUgK0kkMjIUhgA9CizSwLvNgsGK8PfMzhuWfsmZ7T/FT10GbMEfzq7qnqr+uaVpIWiEUOkZVI8+rXWu+VLc0LZH9IZLVtl2RyVb4tdbCcAMbGhe2F2Dg+TNuTzmzbuAkMJ8HGE6RUKtxl9NAeRQWCVwdoS0V3PV2g92GA4ZcxPn0eYXA1xnJtUjQlHj6d4ea9K9x5MMLt+wPcOOmj887E+cUl2p0LrA0fWVEdR7igy2OCTuYrjK7n0Ewb4TaBSHO8Otfx+LSL9ose2q8dvP8YYa7HOHl0hlt3n6M3NFDt95RRA6gZNqYEmy80TGZLCeUycD07Z2s8edbFyzd9vO3G0KyC7pSwgj107we2SUnnnCIsfgNNy8X3pY7FyoBuOhLGYsfhJEZ/nGKqlTAdAS/KsKN7OTWnKAqZBcPY9wBc6Ra+fpthNJ5hTXtukB/upE2kKRKRkAQphx/FVA6BgEriR4mE/9VlhnAdOSrbDeH60aHLAV32w4Q6m1J6WQ0KYymPHlV+R0BOc0b1W2obmTZ3mEeI7QoQETCKU1j0IIPqb+Lfc8jpcXQBpcBOylEN75ZA6ryNs/pbkv1/sG03kKPiBVt5VmJ7We2lKlJO88Yrz6e6q/wOg80bTsFy6/o1a8LKi1ICeOUxav569bg0Imz+y0rNF5vgQwnEMaSZ0U8YpdPEhA8mKwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/3e0a8ddfc0a37752e5d39d8105fd911f/e93cc/Shared-Styles%402x.webp 300w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/b0544/Shared-Styles%402x.webp 600w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/68fc1/Shared-Styles%402x.webp 1200w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/a2303/Shared-Styles%402x.webp 1800w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/4293a/Shared-Styles%402x.webp 2400w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/55654/Shared-Styles%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/3e0a8ddfc0a37752e5d39d8105fd911f/aaafa/Shared-Styles%402x.png 300w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/8db2a/Shared-Styles%402x.png 600w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/6ca50/Shared-Styles%402x.png 1200w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/4d07a/Shared-Styles%402x.png 1800w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/ee4de/Shared-Styles%402x.png 2400w", "/static/3e0a8ddfc0a37752e5d39d8105fd911f/9391c/Shared-Styles%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3e0a8ddfc0a37752e5d39d8105fd911f/6ca50/Shared-Styles%402x.png",
              "alt": "A collection of text styles, colors, icons, and box shadows showing Heartwood's shared styles",
              "title": "A sample of Heartwood’s shared styles.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A sample of Heartwood’s shared styles.`}</figcaption>{`
  `}</figure></p>
    <p>{`We began by focusing on how we could apply the principle of Humanity to our color palette. Citing the `}<a parentName="p" {...{
        "href": "https://99percentinvisible.org/episode/curb-cuts/"
      }}>{`Curb Cut Effect`}</a>{`, we elected to make accessibility a focal point (WCAG AA to be exact). This led us to change our "primary" color group from a hard to read green to a much punchier blue.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.57142857142857%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAt0lEQVQY04WOyw6CMBBF+Vn9BnfuXPsBunDp0l9wJ67cmBgMEBNjFEVebWlleqUEFF/xJE06c2dOxkKN1rp6hmUQwwsuuGYMIRfQIHh7jo13RhRniNMcXKiPPYPVFlIdjN0jOraD3spH195iEaVYOwz9kYvhdIfBxMdsHvwXNoEkjUgqHJIMiSrKmkBlj4kCp5AhYQq5JLP1W9gWV5SSm+DvMaQUJnydbfH1wuZP+nnBo0f662zDHURsg7wypuTZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/56eab4f777b67181abb25aa13d6e9a5c/e93cc/Color-Updates%402x.webp 300w", "/static/56eab4f777b67181abb25aa13d6e9a5c/b0544/Color-Updates%402x.webp 600w", "/static/56eab4f777b67181abb25aa13d6e9a5c/68fc1/Color-Updates%402x.webp 1200w", "/static/56eab4f777b67181abb25aa13d6e9a5c/a2303/Color-Updates%402x.webp 1800w", "/static/56eab4f777b67181abb25aa13d6e9a5c/4293a/Color-Updates%402x.webp 2400w", "/static/56eab4f777b67181abb25aa13d6e9a5c/55654/Color-Updates%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/56eab4f777b67181abb25aa13d6e9a5c/aaafa/Color-Updates%402x.png 300w", "/static/56eab4f777b67181abb25aa13d6e9a5c/8db2a/Color-Updates%402x.png 600w", "/static/56eab4f777b67181abb25aa13d6e9a5c/6ca50/Color-Updates%402x.png 1200w", "/static/56eab4f777b67181abb25aa13d6e9a5c/4d07a/Color-Updates%402x.png 1800w", "/static/56eab4f777b67181abb25aa13d6e9a5c/ee4de/Color-Updates%402x.png 2400w", "/static/56eab4f777b67181abb25aa13d6e9a5c/9391c/Color-Updates%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/56eab4f777b67181abb25aa13d6e9a5c/6ca50/Color-Updates%402x.png",
              "alt": "Side by side comparison of old and new components",
              "title": "Primary color adjustments for better accessibility.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Primary color adjustments for better accessibility.`}</figcaption>{`
  `}</figure></p>
    <p>{`Next we focused on applying Physicality to our colors. In particular, we noticed that the use of pure grey felt too computer-y. Nothing in the physical world is 100% neutral grey. To make the product look more natural, we saturated our greyscale. The result is a greyscale that feels more like ink or stone.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.142857142857146%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABtUlEQVQoz62SzYtSARTF3zJol7iLoI3QKmghNNDONu3erpWzbFVGi9mUtZhFwRgRszAaIWwWo7WwSCacAitRNEfS/JpoTBoFdfx+H+q89/Q3z7foL+jC4azuuT8OV5jP5+i6zmw2Q1EUBoMB7XabRqNBrVajWq1SKBTIZrMkk0ni8TixWIxoNEokEiEUChEMBvH7/ZYLyzBVVen3+1ZIqVQilUqZS3uEw2ECgQA+nw+v14vH48HtdiOKIi6XC6fTicPhwG63IwgCNpsNYUmoaZpJeMJkMkGSZEajsXlgQKfTodVqcXTU4E+9zqFJfHDwi0qlQrFY5Ec+z34uRyaTIZFIkE6nEfjPYxIuzA7nTGc6sjpjMJRptnr8rjf5WT7ke67Ml+Q+Hz8leBf9zM7bD7zafoN/6zXPNrd44ttk/fFT7t5b44H3EYJhLMM0xvKU477E32aXfLnG11SB97vf2A7v8vzlDusbL1h7uMGtO/e5uXqbG+Iq11wiV65e59LlFc6cPcf5CxcRFosloYFuGJZrms7Jv06nlmRFtSTJCuOxxMjUcDgyP2JI31TP7Pu426Xb63EKqmrTdn+lz+AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/3e358af29ed3975ff2708120710a6b1b/e93cc/Grey-Scale%402x.webp 300w", "/static/3e358af29ed3975ff2708120710a6b1b/b0544/Grey-Scale%402x.webp 600w", "/static/3e358af29ed3975ff2708120710a6b1b/68fc1/Grey-Scale%402x.webp 1200w", "/static/3e358af29ed3975ff2708120710a6b1b/a2303/Grey-Scale%402x.webp 1800w", "/static/3e358af29ed3975ff2708120710a6b1b/4293a/Grey-Scale%402x.webp 2400w", "/static/3e358af29ed3975ff2708120710a6b1b/55654/Grey-Scale%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/3e358af29ed3975ff2708120710a6b1b/aaafa/Grey-Scale%402x.png 300w", "/static/3e358af29ed3975ff2708120710a6b1b/8db2a/Grey-Scale%402x.png 600w", "/static/3e358af29ed3975ff2708120710a6b1b/6ca50/Grey-Scale%402x.png 1200w", "/static/3e358af29ed3975ff2708120710a6b1b/4d07a/Grey-Scale%402x.png 1800w", "/static/3e358af29ed3975ff2708120710a6b1b/ee4de/Grey-Scale%402x.png 2400w", "/static/3e358af29ed3975ff2708120710a6b1b/9391c/Grey-Scale%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3e358af29ed3975ff2708120710a6b1b/6ca50/Grey-Scale%402x.png",
              "alt": "Side by side comparison of old and new grey scales",
              "title": "Original greyscale (left) compared to Heartwood greyscale (right).",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Original greyscale (left) compared to Heartwood greyscale (right).`}</figcaption>{`
  `}</figure></p>
    <p>{`We began our type exploration by searching for a super family with the right features. It was important to pick a family that included a sans and a monospaced typeface. Having both would ensure a high level of flexibility while maintaining cohesiveness. We’d consider a matching serif face a bonus, but not necessary.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.214285714285715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAy0lEQVQoz31RyxKDIBDz/7+y1hcvUVDR1sOW7AwMTh0PUQhLdhOqNXxo278Z4Tj5X/LgwnHHnX/1FT6z36gXhoQayc5LPtSjY16ZmSa3MrdsR6yzJPVExnrS1pFf9yxaoQNE6magppfU9ooLwKMB+HcnqBt0FqzbgV7MS75jJn+dEJuE0n7JPwFNLoKw0wnNU+hxzoewynwE1qkJLGP6XhqOC26SOAu6JZCKQsgDuaVMEAUaIKtkC+B9rAV8nO7yKGlx92oll/inFwZ+hDcaUOaZq+YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/33859d804a838e3f454007c80a0ee4b1/e93cc/Type-Families%402x.webp 300w", "/static/33859d804a838e3f454007c80a0ee4b1/b0544/Type-Families%402x.webp 600w", "/static/33859d804a838e3f454007c80a0ee4b1/68fc1/Type-Families%402x.webp 1200w", "/static/33859d804a838e3f454007c80a0ee4b1/a2303/Type-Families%402x.webp 1800w", "/static/33859d804a838e3f454007c80a0ee4b1/4293a/Type-Families%402x.webp 2400w", "/static/33859d804a838e3f454007c80a0ee4b1/55654/Type-Families%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/33859d804a838e3f454007c80a0ee4b1/aaafa/Type-Families%402x.png 300w", "/static/33859d804a838e3f454007c80a0ee4b1/8db2a/Type-Families%402x.png 600w", "/static/33859d804a838e3f454007c80a0ee4b1/6ca50/Type-Families%402x.png 1200w", "/static/33859d804a838e3f454007c80a0ee4b1/4d07a/Type-Families%402x.png 1800w", "/static/33859d804a838e3f454007c80a0ee4b1/ee4de/Type-Families%402x.png 2400w", "/static/33859d804a838e3f454007c80a0ee4b1/9391c/Type-Families%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/33859d804a838e3f454007c80a0ee4b1/6ca50/Type-Families%402x.png",
              "alt": "Text samples of families that we considered",
              "title": "Some of the typeface families we explored.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Some of the typeface families we explored.`}</figcaption>{`
  `}</figure></p>
    <p>{`We ended up choosing the Source family. These faces had the widest language support and largest set of OpenType features of anything we looked at. Even some of the paid options we considered had fewer features.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.857142857142854%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABFElEQVQoz32S627DIAyF8/7vt61V7jRXGnIj123SGbZKRZNqP45sLLA/23jTsmNev2EtadmMHr6rZfs56fjWcwN63liqn9ENCwa9oB9JM8ZpNbGJ1faapTrNcZuD5FmHqlWyRVZIhPENX9cQn0ZkPy4B/DBFnOZouhFF3aCsFfJSmuL6fUIb1PPOhFSdaIiSyZhyez6kTtb9l0FeWj4SirxGeiuRiAIXP0aUZExGvsgqxCI3dyrcVf8yv38IN7Q0PzMb8u38rOhM1ERL5+NSn0shQmXmU0qF0FARURALXIOEKYNIwI9SZGZulblDs5YO5SkhWapMSWmDdzVANj3bph24xfaxgKNO38YNvvtrriyNK/f9HwY8sTNvOqFOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a8b8b42acc913d55171266a5719b718e/e93cc/Source-Family%402x.webp 300w", "/static/a8b8b42acc913d55171266a5719b718e/b0544/Source-Family%402x.webp 600w", "/static/a8b8b42acc913d55171266a5719b718e/68fc1/Source-Family%402x.webp 1200w", "/static/a8b8b42acc913d55171266a5719b718e/a2303/Source-Family%402x.webp 1800w", "/static/a8b8b42acc913d55171266a5719b718e/4293a/Source-Family%402x.webp 2400w", "/static/a8b8b42acc913d55171266a5719b718e/55654/Source-Family%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a8b8b42acc913d55171266a5719b718e/aaafa/Source-Family%402x.png 300w", "/static/a8b8b42acc913d55171266a5719b718e/8db2a/Source-Family%402x.png 600w", "/static/a8b8b42acc913d55171266a5719b718e/6ca50/Source-Family%402x.png 1200w", "/static/a8b8b42acc913d55171266a5719b718e/4d07a/Source-Family%402x.png 1800w", "/static/a8b8b42acc913d55171266a5719b718e/ee4de/Source-Family%402x.png 2400w", "/static/a8b8b42acc913d55171266a5719b718e/9391c/Source-Family%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a8b8b42acc913d55171266a5719b718e/6ca50/Source-Family%402x.png",
              "alt": "Samples of Source Sans Pro, Source Serif Pro, and Source Code Pro",
              "title": "The Source family.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The Source family.`}</figcaption>{`
  `}</figure></p>
    <p>{`I developed an extensive type scale based on a formula that creates integer font sizes, which are as easy to work with in code as they are in design. Using a formula enabled us to generate far more sizes than we would need for the time being. As new challenges emerge the formula provides a starting point to add new sizes. We narrowed down the initial set to a few styles for immediate use.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.64285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB30lEQVQ4y42T6XKjMBCE/f6vt5vY8YEPbgkhQOAz0ez0ECgoO5X90TUcovnoFgtj23tZdV8sUsb5XFdUlM5XTfeA2vPt0V3u4/xNCxhBhe18qhpKMiOmeWH5uCBTOTpfH8SL/0sLJvRs6IuyZRPnM2VJm8brsmZj61VReabzvHg2W8wXgiFBoNSlo0xVMCQ2ozQ3lLDYdCRgs9l8RchmMIUhU+reMFMlRakWxayibJ4MX2nMsM+xFTMuhVI2RIZhooQ04lm7s+T5E91IOEixWZpbmac4p2OU0f6U0P6Y0ImPIdt01P1AJ4S1u9CgqrnKdN2NbI0onAify1tIskQ81/sX61NoB+LRcIq7OyS03Ye02R17BSemi/l6RB+bvZhqNn9bBbT9vhcm+SzbxeCME2Mb2X+ZMlJKrkuZKZ+DFOuwZr09UMiRFGUt16clzQxB9Od9Tat1QEvW22orZQzb5nL75GNL7x87uQdpU82KWkwbUxO6TPfSpqdApngQVCv+fLz8GKZcYDXbSjNCNBtwXgduNuB88FCcaaEwtv8FYfB3uZEvWDIpttaT4XACwijt9x02M/4WNAwT113FELR4GQpBHCB/KmW4gMCRF4hQyFAEzIZ1aDqWP0gJAO5NS/kHiv91QCXqaJIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/aa79724bf3d5216713d6d3ccb76df5a3/e93cc/Type-Scale-and-Styles%402x.webp 300w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/b0544/Type-Scale-and-Styles%402x.webp 600w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/68fc1/Type-Scale-and-Styles%402x.webp 1200w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/a2303/Type-Scale-and-Styles%402x.webp 1800w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/4293a/Type-Scale-and-Styles%402x.webp 2400w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/55654/Type-Scale-and-Styles%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/aa79724bf3d5216713d6d3ccb76df5a3/aaafa/Type-Scale-and-Styles%402x.png 300w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/8db2a/Type-Scale-and-Styles%402x.png 600w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/6ca50/Type-Scale-and-Styles%402x.png 1200w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/4d07a/Type-Scale-and-Styles%402x.png 1800w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/ee4de/Type-Scale-and-Styles%402x.png 2400w", "/static/aa79724bf3d5216713d6d3ccb76df5a3/9391c/Type-Scale-and-Styles%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/aa79724bf3d5216713d6d3ccb76df5a3/6ca50/Type-Scale-and-Styles%402x.png",
              "alt": "The Heartwood type scale and some of Heartwood's pre-rolled type styles",
              "title": "A segment of Heartwood’s base type scale and a handful of pre-rolled styles.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A segment of Heartwood’s base type scale and a handful of pre-rolled styles.`}</figcaption>{`
  `}</figure></p>
    <h2>{`Components`}</h2>
    <p>{`The component design phase began with the humble goal of parity. It was important to make sure we didn't forget to update any existing components. This would let us phase Heartwood into production without breaking anything. To ease the transition, we did a thorough audit of the existing codebase. From this audit, we redesigned each component to use our new shared styles.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACCUlEQVQ4y4VSy27UQBDMLyPxCXwFXAhSxGFzACEkIkFEgNWCEg4RQgj2gEjWsdevGT9mxjNjU/TM2t5dFotDq+dZXV3VR0IZDFFLjU5bwLaAaX12e3fuou3oDh1F63PXbe52MY4GoIpCNwaLpMaTmxyzFfP5I+01vVHa4NNXhtmbCC8+xJidR1h8Yf58KHgAaInV0+vvuPdqjgcXV7h/NsfJ9Q8Y3fqP558zPHy2wslZiEfPA7y+TNFMAbpQpsP87QVmx4/x8vSU8jEW796jsb9RiQbaGFhrIemPWxs70bIHddkBExvdbZRyQTX8J0ly5LzyoUhX2dhR9928x9CtM1YiijPwUqCoJMpajdXdnhGgVNQNFZA7QEL9BejZ0TpgFW6zAmlegBe1BxkAM66QMIGqlvSnQU0ybNlNMGS8RBitEceJj7ISI2BJ4HnOwRgVo3eOpdhjeQBoSaMCwd0dspwhZxyl2DIsiRkvqGXPTpHDzpSN9v8GJKGXyyXWcUoaSq9RQ5/dSCkyaxWECMIErBDkcusZ/tcUTu3ESY6K2otrjau0huqrO02dYU6GIFa4icQ4hxMtG/xkNcKUoXAuC42oUr4lNyZxxhHGzBuVFwqsbKY1HHT4llVY0+gU/dhIcrLuW74NIvwKUgKTdCd2xkYfDvYArJvt0I4D39850IZCKrM3Lt6Y/uwP0bQtu8gy4AQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ee7975fc24e32aa767624af716c0e0f9/e93cc/Components-Round-1%402x.webp 300w", "/static/ee7975fc24e32aa767624af716c0e0f9/b0544/Components-Round-1%402x.webp 600w", "/static/ee7975fc24e32aa767624af716c0e0f9/68fc1/Components-Round-1%402x.webp 1200w", "/static/ee7975fc24e32aa767624af716c0e0f9/a2303/Components-Round-1%402x.webp 1800w", "/static/ee7975fc24e32aa767624af716c0e0f9/4293a/Components-Round-1%402x.webp 2400w", "/static/ee7975fc24e32aa767624af716c0e0f9/55654/Components-Round-1%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ee7975fc24e32aa767624af716c0e0f9/aaafa/Components-Round-1%402x.png 300w", "/static/ee7975fc24e32aa767624af716c0e0f9/8db2a/Components-Round-1%402x.png 600w", "/static/ee7975fc24e32aa767624af716c0e0f9/6ca50/Components-Round-1%402x.png 1200w", "/static/ee7975fc24e32aa767624af716c0e0f9/4d07a/Components-Round-1%402x.png 1800w", "/static/ee7975fc24e32aa767624af716c0e0f9/ee4de/Components-Round-1%402x.png 2400w", "/static/ee7975fc24e32aa767624af716c0e0f9/9391c/Components-Round-1%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ee7975fc24e32aa767624af716c0e0f9/6ca50/Components-Round-1%402x.png",
              "alt": "Component updates including buttons, button groups, messages, and list items",
              "title": "Old components (left) redesigned with new styles (right).",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Old components (left) redesigned with new styles (right).`}</figcaption>{`
  `}</figure></p>
    <p>{`The previous round of component design was lacking in detail. This caused a problem for the design team. To see a component in detail, one would have to look at production, which was often unstable. This situation meant the design team was often blocked from making progress. To solve this problem, we added in-depth details to our design documentation.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.64285714285715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC7klEQVQ4y21UyVIUQRTkqzz6DV40woNnL/6BEd65GIhycgkFxUDFDTVwQSRARQJZlIEh2Gfr7um9urt6HdJX1dPjLBwqqvvVq6zMfK9qyOcxgiiF7TLUGwq0pgLXtQG0aGRI0hRxkkHRTByd1FCpKag1NPAwBo8SBGE+JA7NQ+IjilMcNzz82rLwZYXj9YKBF19VzCxqWCs7UIwQDuOw3BAWS6GasYw1LQ6bRfCCuAMqAcVJus1RUT2UDrgEWdow8eOPifKxS2shmB/SZg7TzYFETHzbLBwEjJMU+1UXsz9VfFsXQJY8REiOkwQhKTBsnxhyuH4M14vkfKZkgZ5mKdZ3HYw+q+D28yrGpqsoHTIwWisSBaO6zkkqR0PPGRZARY5k6LUZlo8Zpsm36XkNTz8p+LhsQDNDycLniWQnQKRUJ4JNLLuB/gMSi1Yrw+KGhcs3Srg6XMbF61t4/12XlWZ+nizMN5ycpZgtNgiYS6afhBjuHDHcn6ljaq6Ju2/r+E2FES1TGO60fbO9pONjIbeHYSACAp1zBL4Dj9loZSH5mkmwItl2fWi6BVU3CTwgK9J831l9KBYN28PhiWhaHbrFOolBmK+rTQvVuoaGqsOi3IC3QfoZFj/MF5JIRpgRg5iMpx4TjexQq1BM2dxF48MSzNEp7NyZxN+RcZQfvER1fgWOaiKIs15A1xO3ISCZkRziAHmIlzeuTawd3QFTTNgEYCuGnB3DgU95Awwd2lg0rqggK7qfwCOq9/7MPDaH76F0awKlkQkYexWEFBfvwICHOcO8LUw3r2anggQY0jtRXVjF7sNX2Bt/g70n72CR31JmEJ3toZAlWHVXtgN4ClTmlrF18xG2xyaxTR4aBzXwtEXrcVcBuxjmcywZFs0sAelREJJLo48xe+4SFi5cw+fzV1BfXJOSC/8GAPNChPKa9TBsv5cWvYfGUR12VZNyPTegeO8tkYD9V0ew7I/JXqVHlmenUqaYxSH9YGL8A0x1SxbnZfDjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/e93cc/Button-Documentation%402x.webp 300w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/b0544/Button-Documentation%402x.webp 600w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/68fc1/Button-Documentation%402x.webp 1200w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/a2303/Button-Documentation%402x.webp 1800w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/4293a/Button-Documentation%402x.webp 2400w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/55654/Button-Documentation%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/aaafa/Button-Documentation%402x.png 300w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/8db2a/Button-Documentation%402x.png 600w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/6ca50/Button-Documentation%402x.png 1200w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/4d07a/Button-Documentation%402x.png 1800w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/ee4de/Button-Documentation%402x.png 2400w", "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/9391c/Button-Documentation%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/37ffeb881ae3b0fbba63dc3b61a5d4ae/6ca50/Button-Documentation%402x.png",
              "alt": "An example of some of the more in-depth documentation we began to create",
              "title": "An example of more in-depth component documentation",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`An example of more in-depth component documentation`}</figcaption>{`
  `}</figure></p>
    <p>{`Next we focused on new components that would improve the product experience. We found low hanging fruit in cases like the nonexistence of a checkbox component. In other cases, the solutions we were after required more discovery.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.85714285714288%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAADAUlEQVQ4y21UXUsVURS9/6rnoH5ABD1ElEIFQQW99hN6sF6EEIlI0xC1ECEooSxCEUxDycrE1CT13vk+M2fOOTOz2nvPvV5vNrA532uvvdfe00i1hcoMeMxyR/POuruX0DpJTc89Nm0KnHyvyBqdRZLmiJMERVHAFSWMLaBoL6E9oAB/ZVnS4xxBGMHzAzRbHnJjoIVIjdNgJswqNxZeoLB3oFCVBV0iJnTW9BU+fwvxZTPGzz1FrMh5QqPOYa2luyW97QGsKfMmW6SMXHLENKYw17YUPq4GeLvkEWAqIGmq4VxB0ZQyZm1SPYCawJyzcPSAHxnDDG2bhUNZODnjkJstHyGF7QcRgWf/MGxPeMwojDoFuVyoTbf3jOSZ53GcSMiGnVWVkDkG7IqiSZRMcmesq0VRGgGxYAfMgvfl3NSmc0MRuF5ROpMw0QhjAlV1PpR2CKKMwovpLJe9JLUyRnEsKqNyoj47OgUYEWDLC0RtTnZZgR6l2NltohVkJJaFH1PYWa2+Uhm29xNRnkl0tDgGjOlCFEU4+SXk5PAoFFZZTinInLBMdQnyiWevD/BgdAe/D0kYewqQkk8CFETNurq4OYe+HyKMlOSO88jsEnYeK6kKKnc5O1WHWW7lgBPdEcWyEXi9djJnQM/ziXmTRKQmoNT8V2Ue/SAWz9xytdXKcz3W6pc4arawtrYu+a7btJCS4z7mkhLAzsKPNLwwl3rUXG9JSmKlpHZC/RvDFBWCwya2lpcFuGJAzp3UZ13LxyFbbiNKtCm6ohSOyoIeVfQIbPTlWmPvzz4C+il0vsyVvSEbV2FzawfjE68wMTWDkfEpjI5NYn5lHU8XVjD0YQmP3y/i0bsFrKxu4NfzWfwYmcH3J9P4OjiG5sY2MmKvqPcb3E40x6fFZfRdv4P+G3dxue8Wrly9icHJWVwcfoHzD4dxbmAYZwaG8HL6DVb772P+0j3MX7iNubPXsDu3iJyYKpWf+NtQwispAsjInyNPFXsruwccjaYpW962zHZD/gvZ6VUMWxZG2gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ff2ddd011cf6f7ff5201cdf913befaa0/e93cc/New-Components%402x.webp 300w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/b0544/New-Components%402x.webp 600w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/68fc1/New-Components%402x.webp 1200w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/a2303/New-Components%402x.webp 1800w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/4293a/New-Components%402x.webp 2400w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/55654/New-Components%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ff2ddd011cf6f7ff5201cdf913befaa0/aaafa/New-Components%402x.png 300w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/8db2a/New-Components%402x.png 600w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/6ca50/New-Components%402x.png 1200w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/4d07a/New-Components%402x.png 1800w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/ee4de/New-Components%402x.png 2400w", "/static/ff2ddd011cf6f7ff5201cdf913befaa0/9391c/New-Components%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ff2ddd011cf6f7ff5201cdf913befaa0/6ca50/New-Components%402x.png",
              "alt": "Some new components including a date picker, modals, toasts, new form inputs, and lists",
              "title": "A sampling of new Heartwood components.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A sampling of new Heartwood components.`}</figcaption>{`
  `}</figure></p>
    <p>{`One of the biggest pain points we kept hearing from users was that they had to jump around a lot to do their jobs. This felt unnatural, awkward, and frustrating — the antithesis of what Spruce should be. Even if a user knew where everything was, there was a lot of opportunity for improvement. We asked ourselves what role Heartwood components could play in solving this problem.`}</p>
    <p>{`To figure out which components we needed, we did some competitive analysis. The all-around best solution we found was the concept of a card-based interface. This would solve a couple of big problems. First, we could serve up custom tailored content to specific users. Second, we could use cards to shift the product from task-oriented to people-oriented. This people-centric approach felt more in line with Heartwood's design principles.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.28571428571428%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABmUlEQVQ4y41U2XKCQBD0/78qR6WSqEkePEC85RIFBRQjKna2J4VFebJVW7PuzrbdM71UUBjH41Hi4XCAN5vDDxZYhhFc10Og1vn5eX5xVIqHeUKSbOA4U1i2g9HYxGA4Rn8wkrU79eD7ATab34t7NwGZbDsulssQq9Va9sla07vQOz0Ei6Wcce+caeWahCzL4HkzYThVcb/fyz7XZEr5cbySvJuARYaMjjvFZGIhjGLFJMN2m6Jj9NFq6wLKkjwELEYCUW7OjoyaLR2zua8aFUodS0vmZdaIkxc51usE3d4A44kp5aAD7gIWJe92O6RpKpMMU/Wbf5J3m+UwTbt8DU3LwUf1SySalq3kR4ji+GQZxo7RO5XjoWQCsMO8mEcCDUf/DLnX1oyLut9kSKnrJEGoXgnlOuqlkPHTyxuqtW8x+PtnvTwg2Wi6ITap1X+gdbrCsqrWTWUb1o/ndwGvSWZXn1/f0WhpYmqy0hU4nyabxFja2PmgFznHSmajqUmnjW4ftu3eB7z1BSETmjlSRs+/RDQ2a3wt/w+1yYXsWNMuFAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/e93cc/Cards-Sketch%402x.webp 300w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/b0544/Cards-Sketch%402x.webp 600w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/68fc1/Cards-Sketch%402x.webp 1200w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/a2303/Cards-Sketch%402x.webp 1800w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/4293a/Cards-Sketch%402x.webp 2400w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/55654/Cards-Sketch%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/aaafa/Cards-Sketch%402x.png 300w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/8db2a/Cards-Sketch%402x.png 600w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/6ca50/Cards-Sketch%402x.png 1200w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/4d07a/Cards-Sketch%402x.png 1800w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/ee4de/Cards-Sketch%402x.png 2400w", "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/9391c/Cards-Sketch%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2425f8fcc7a2b31c03ad3efdafd3fbf1/6ca50/Cards-Sketch%402x.png",
              "alt": "A sketch from our team's brainstorming on what to do with cards",
              "title": "Results of our team’s brainstorming about cards.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Results of our team’s brainstorming about cards.`}</figcaption>{`
  `}</figure></p>
    <p>{`We spent a week or so brainstorming about cards. We focused on what we could use them for, where they would live, and what types of content they'd need to support.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "115.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAABYlAAAWJQFJUiTwAAADh0lEQVQ4y31VO28cVRT2b6KBhhJRIBqIBAKFgpJEFKCIBgmlo4go0iCBACEREikmQnFsxciBNU6iJQ4UoGy0RIkfa6+9u/OeuXfm3nl8nHPGM157l4x0987ex3e+77xmQWmDRFuo9ORIZP14zN+zJ/Z5XmhelCweb5w2EKsMUZLK3K5PnWvuLDQvYRRjMnHhOB6CMITNc+Q0jM2RmgJRpDAauYhjDc0AKqX1XEikmW0Zt4A6JQYEGicJsszAGAtrrcxFUeIwiPBsNMEkSpASiNYZyrKSdzbaSuYfndUXD0djYpDA9Xw4rifz/vCAjCiSGsMPJvB8VwwntKaUFqNlVZ0E5D/MSumUpGixaC3LrZmjytF9FODStQHu/hMCKEmmEbcwe2Y6A8jWBnv7xCIUq3leCCgf5kteZPB0L8GhowgsQ1GW7Z6ls+oo2gJo8gqu6+Dixx/CpJoClGB4cEgBcmvQChjuDzA6IIO+J8ZLAuSnIrnGFicZcrRcP8Ht1XUEkRYfeV4gh3NiQHh41n+Mne0dRLESIPYb7wtLAm8BY/phFtd+HeP85W0srjuwRpPzQwIrRI4tKnjOBEMKEKcX4RwDVrXsGR8Sa1AagdRLYDiKtSSZ8PTJE2EYhNEsw6KYrZQ4jqEDBymlhjseY3dviAebD/Go16MKUdjq9zDYHYgx9h+D1IDlLCCXVLe7iYc/fY/+yo/4Y/E7XP7ic7z51hs4++4ZrKyukfRKKoHd0DwMVAhgKRUjgDrLKRAJNu528ffKDfy7toTtzjIufvIBXn39Fbxz9j3cvN2pZZY1WK/3GH9tPqjXCKwOyhSgT4BXrl9H54ev0F/9Gf21ZXz75dd44aUX8fJrb2NxZeMIsE6VTmcdv9xaOuJZO7kFbLrGjaVl3Ln6DXbv/4at7gbu/d7FhU8/w/vnPsKdjT/F+Zkx4s+J61OahZRCiVQTV8xMlCNi6dOhNE2RUblxk2AQyUXuPJzgNGd01g8yqnMq0yRDTkZsZqBmuk1WIkioTemizrOyaiVyigT7B3C2duBR9D3Kx8wPYCknDaWRoVkAT/dDTmaPDvKwUw2CI8vyxhOPWKR0nlRQd+I9nvN5ic0tyiHfcHNQmjqOMTLYb7n4iMozrJU0NVy1yT0V5eN2X8CPc7F2+mlqtR5m7kgayfO/If9z4Tmjubegpr5Yz72g53+8pj9YHOX/AIY60fAq6lkSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abf40112c1f80e4b83b9776fadf2976f/e93cc/Cards%402x.webp 300w", "/static/abf40112c1f80e4b83b9776fadf2976f/b0544/Cards%402x.webp 600w", "/static/abf40112c1f80e4b83b9776fadf2976f/68fc1/Cards%402x.webp 1200w", "/static/abf40112c1f80e4b83b9776fadf2976f/a2303/Cards%402x.webp 1800w", "/static/abf40112c1f80e4b83b9776fadf2976f/4293a/Cards%402x.webp 2400w", "/static/abf40112c1f80e4b83b9776fadf2976f/55654/Cards%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abf40112c1f80e4b83b9776fadf2976f/aaafa/Cards%402x.png 300w", "/static/abf40112c1f80e4b83b9776fadf2976f/8db2a/Cards%402x.png 600w", "/static/abf40112c1f80e4b83b9776fadf2976f/6ca50/Cards%402x.png 1200w", "/static/abf40112c1f80e4b83b9776fadf2976f/4d07a/Cards%402x.png 1800w", "/static/abf40112c1f80e4b83b9776fadf2976f/ee4de/Cards%402x.png 2400w", "/static/abf40112c1f80e4b83b9776fadf2976f/9391c/Cards%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/abf40112c1f80e4b83b9776fadf2976f/6ca50/Cards%402x.png",
              "alt": "Seven variations we came up with for cards",
              "title": "Examples of cards that tie to specific use cases.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Examples of cards that tie to specific use cases.`}</figcaption>{`
  `}</figure></p>
    <p>{`We landed on a few variations that each tied to at least one use case. These variations informed the design of the card components.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.85714285714286%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABrklEQVQ4y4VUu27DMAzM/8/9i3bo0jVAhwJdunVu+kgNJE78kPWwLdssj7b8QAx3IJSI5Ol4vGSnbUXG1YRTaUd5YakwpdwZV9E8j1zONVO+HnPh8y58QfHnd0QfX790TdWssM8D5MA5xCVRZEsvd/MHF4A4z5eE0kxRVXuqfUOuXL7etK1Ey9F1neQx1QJwQdk6ynLFYJ6bOiorL8xDHo/4piU/nK5i5msMte0ZgN05vjJDNLfjqKHJ8Zgh8Jjm3ArDSXhjHCmlqa4baphByQwyZccmSBECbA0WdQNoJ0BVaErSTEaCRmgKYAjIwNdyBknCxlcZasO2UIUAQnzRkAEVmrgmzQ1d0oIf2bDNHLBnmE+is1Zq8B1AjscfOp0irjMj0I1t5oCFNrJlAGIkaJUPZgfD/VtC9/uYEuVFY0gyZ7e+FGYZAEWjIWfZc6/vGT29xJSqSibA8lYAJ7qurGSz4rWm1xCjYmQAPj6f6e7hyAzrcWmbgCU3zQHxS8iKyTZR7OgQWTZ0b6vqP4YAaQaw4MO8cNMEDATDE3XCEHWbgFshNcNWpz+Nmf7D+Qciv4u4yo5nawAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2cf71e9bf2e32256214a7824f6872fba/e93cc/Card-Anatomy%402x.webp 300w", "/static/2cf71e9bf2e32256214a7824f6872fba/b0544/Card-Anatomy%402x.webp 600w", "/static/2cf71e9bf2e32256214a7824f6872fba/68fc1/Card-Anatomy%402x.webp 1200w", "/static/2cf71e9bf2e32256214a7824f6872fba/a2303/Card-Anatomy%402x.webp 1800w", "/static/2cf71e9bf2e32256214a7824f6872fba/4293a/Card-Anatomy%402x.webp 2400w", "/static/2cf71e9bf2e32256214a7824f6872fba/55654/Card-Anatomy%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2cf71e9bf2e32256214a7824f6872fba/aaafa/Card-Anatomy%402x.png 300w", "/static/2cf71e9bf2e32256214a7824f6872fba/8db2a/Card-Anatomy%402x.png 600w", "/static/2cf71e9bf2e32256214a7824f6872fba/6ca50/Card-Anatomy%402x.png 1200w", "/static/2cf71e9bf2e32256214a7824f6872fba/4d07a/Card-Anatomy%402x.png 1800w", "/static/2cf71e9bf2e32256214a7824f6872fba/ee4de/Card-Anatomy%402x.png 2400w", "/static/2cf71e9bf2e32256214a7824f6872fba/9391c/Card-Anatomy%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2cf71e9bf2e32256214a7824f6872fba/6ca50/Card-Anatomy%402x.png",
              "alt": "Documentation showing how we broke down the card component",
              "title": "The anatomy of the final card component.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The anatomy of the final card component.`}</figcaption>{`
  `}</figure></p>
    <p>{`We opted to move into code before our component designs were 100% complete. The idea behind this was to kick the tires on our system architecture before getting too far. We also found that for certain components, it was faster to make changes in code.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.14285714285715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAACoElEQVQ4y4WUzW/TMBjG9y9z5YLQmDa6la9pQpwQh3FCmoQmwYkhhCYhNC5MMIYG27qmSZM0H3ZsJ47z8Nppi6q0EOmR3eTt7/2015qmgX32919ia2sT/X4f6+v30Nu8i/tbG3j4YNvpyaMdPNvr4+luH7u07/V62N7pY+PObRw8f+wYlrU2A1baQFWYP8dnNU4ugJC3OveBw88aR6cG3z10nhbjgO2LOMlxcRUhimJwxvD2U4h3JzHOLnOcXqQ4/jrBizdDvHrv4+hLhJxLCKnACwlZaswCmwOtwWico+AcUkpyVgJGodYKjbGhG5sHqXb7qqocKOMKaimQCXgEZIy7CM9+nOMHyfNG4Lyg77xVzp1NURRg5JzTKiiADjAjoIuQDKy8kQ8/GCMMI4z8gPYkWkf03n4b+T59C52totS7QPKcZYzSFRQRx3A4xHgcIEkS95s5FXOHVmK6ysUI202SpIjjiTOwKV7fePh9dYPrgddGFkTwggkm5GAySaiJbO5AugixCLRehJAoywqm1tC6ovoosKKkopf0vpVSM1XUGO2ao7Wez84cmJJBrjQ4reNEYjAWNJs1jDGo69UqCWrVSbkohBsZOxKvP4a4tfcLKbPeazcW/1MH+OFa4uCnxOGlgc8oBVO7ObPGZVWv1ErgIJX4NkwxyA2YrFCQkU3Z1seYxqXeVUNpW3DVBWasQJJmZNBACUYlyKd1Npi3sHN+p/dApZ2DBWCU0ewFMURJl4Si7tJ4KCWg69L90Zq166JWAuOMTsc4proZgjJEyQBlLv9eJUtg/wSGNKhBnIImB1ymCLOA4BX9rp10bbDqsTXsADMuEKc5NaIhkEAq6GhRYzQVvZoCO3NIEDtW9jB0LthZWosNWJ7msrRdSWj5A9GhsmDv/a+1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1718ed1d92e9675f5f5522db43805c30/e93cc/Fractal-and-Storybook%402x.webp 300w", "/static/1718ed1d92e9675f5f5522db43805c30/b0544/Fractal-and-Storybook%402x.webp 600w", "/static/1718ed1d92e9675f5f5522db43805c30/68fc1/Fractal-and-Storybook%402x.webp 1200w", "/static/1718ed1d92e9675f5f5522db43805c30/a2303/Fractal-and-Storybook%402x.webp 1800w", "/static/1718ed1d92e9675f5f5522db43805c30/4293a/Fractal-and-Storybook%402x.webp 2400w", "/static/1718ed1d92e9675f5f5522db43805c30/55654/Fractal-and-Storybook%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1718ed1d92e9675f5f5522db43805c30/aaafa/Fractal-and-Storybook%402x.png 300w", "/static/1718ed1d92e9675f5f5522db43805c30/8db2a/Fractal-and-Storybook%402x.png 600w", "/static/1718ed1d92e9675f5f5522db43805c30/6ca50/Fractal-and-Storybook%402x.png 1200w", "/static/1718ed1d92e9675f5f5522db43805c30/4d07a/Fractal-and-Storybook%402x.png 1800w", "/static/1718ed1d92e9675f5f5522db43805c30/ee4de/Fractal-and-Storybook%402x.png 2400w", "/static/1718ed1d92e9675f5f5522db43805c30/9391c/Fractal-and-Storybook%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1718ed1d92e9675f5f5522db43805c30/6ca50/Fractal-and-Storybook%402x.png",
              "alt": "Screenshots of the tools our team used to get components into code",
              "title": "Screenshots of Heartwood components in Fractal (above) and Storybook (below).",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Screenshots of Heartwood components in Fractal (above) and Storybook (below).`}</figcaption>{`
  `}</figure></p>
    <blockquote>
      <p parentName="blockquote">{`Spruce is an experience engine. We couldn’t call Heartwood camera-ready without using it to design some experiences.`}</p>
    </blockquote>
    <h2>{`Onboarding`}</h2>
    <p>{`To begin, we redesigned the global onboarding experience. We identified a couple of core problems with the current flow:`}</p>
    <ul>
      <li parentName="ul">{`It wasn't easy for a new user to add or confirm their personal info.`}</li>
      <li parentName="ul">{`The onboarding experience lacked personality and failed to build rapport with new users.`}</li>
      <li parentName="ul">{`We duplicated effort by having different onboarding experiences on web and iOS.`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.28571428571429%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB2klEQVQ4y41Uy27bMBDM//9AP6KnHopeip4b9NJLgBYpYARu4iCWRUl8U4/pLCUnkqEqFjDYJcUd7e4sdWN9gsC4mDH3L9db/hk388X5kFgXWpjZ+jJ4vr9K+BYQ0TQWparhuA6xJXmCn+AmiG9zTMoxq4SWLw1fPpLs9/4J+1IhdD2McTDWoaUfU8dzAUmsj+9lOPqNVsyywLEs4HxACIHW08ZX9MPAvStKllJu7yp8/PaMP3sNoEffLzGQTJ6rCOXQz/saX38U2L94YOjRtj26TmyHREjpY8kbhHamoncWta5yuZ6BFXuotIMVQYTEhlxJTmKK3VA5QVUNDi/sYVHiUJZ4OBZ4bhromHKGnh+SLLcznBSTkahqjeKkSFzDGotEoq5ts40xstyU+3i1KF9uFT58OuDxGHLzu36gGENWVoj6Ce8P9pTp4djg4YmjY3we7Iw02ihgX8XfnMP5S+nRqW64DswOqCmKCBNIpHlOaZsFer0Ma6IsCJ1h/1Tu44kw7KOMjGcfFYe85JDXabxV59u12cNfuxK7vydoUVabfDPiJEhLoo7lXz3YQvj5e4m7nV0KMqGbcMXYvBFjkBvRQtu4+Deu4ZJ0dbAbEgnZuL/84P/+n2f8A80cdOBYh9/oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/59b866a4270fecae374e809dd64f87c7/e93cc/Onboarding-Card%402x.webp 300w", "/static/59b866a4270fecae374e809dd64f87c7/b0544/Onboarding-Card%402x.webp 600w", "/static/59b866a4270fecae374e809dd64f87c7/68fc1/Onboarding-Card%402x.webp 1200w", "/static/59b866a4270fecae374e809dd64f87c7/a2303/Onboarding-Card%402x.webp 1800w", "/static/59b866a4270fecae374e809dd64f87c7/4293a/Onboarding-Card%402x.webp 2400w", "/static/59b866a4270fecae374e809dd64f87c7/55654/Onboarding-Card%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/59b866a4270fecae374e809dd64f87c7/aaafa/Onboarding-Card%402x.png 300w", "/static/59b866a4270fecae374e809dd64f87c7/8db2a/Onboarding-Card%402x.png 600w", "/static/59b866a4270fecae374e809dd64f87c7/6ca50/Onboarding-Card%402x.png 1200w", "/static/59b866a4270fecae374e809dd64f87c7/4d07a/Onboarding-Card%402x.png 1800w", "/static/59b866a4270fecae374e809dd64f87c7/ee4de/Onboarding-Card%402x.png 2400w", "/static/59b866a4270fecae374e809dd64f87c7/9391c/Onboarding-Card%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/59b866a4270fecae374e809dd64f87c7/6ca50/Onboarding-Card%402x.png",
              "alt": "Some examples of the existing card as an onboarding card",
              "title": "The previously designed onboarding card.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The previously designed onboarding card.`}</figcaption>{`
  `}</figure></p>
    <p>{`The new card component was a perfect candidate for the task at hand. We had already considered an onboarding card as one of its use cases. The advantage of having some of the simple design decisions already made was that we were able to focus on the trickier parts of the problem.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.28571428571429%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACkUlEQVQ4y4VUSU8UQRTmbxoveMWDB2MCB6Px6NGDHLh4wiViDAERMWERxwG3iEsYJvYwMww00/Re1bV013y+rnYWvHh4qa6vur/63vfe6ymWKXChUa1VMC6qyOQYo+cRNnx3AhvyTI3IZIEw0QhZDnqEyIFMDRDTPiA804SVOK0JL+DHdIkEpIE9G/JMVWQGcXAK19mB79TAOntQvY/gQRduew9+axdxqw55UofwHfR7B/CcdwidD5C9fQj34C+hLgkFeHlraxti/SoOlubw4/kt6DfTSA5XwDdvoLt8HbuLdyDXriD99gjp3n2Eq9PYXryHcOUa+O5tS1alnJXSDak5AWuuwWtsIXbeQ3XoAv8YvLWFoLGBwKlDdzYhvCPwk0+IG6/Rb9Yg29tQp58rhcIq1NbgJMtx1GVwfVKsDGgLSdF2BVpnDGEqEJF3mjw78yWa3ZR8FIRr62smJzyUSqPX55h72MDbfQ/AAMYUEPTSg6fHWHjZgSk0tFZ0ZvBi8wx3F5qIEoGB0cjz/J+i0CZhAn7EbERpZg9TLuGHhIUpgiglAmZbJIw5LggP4yr4ROtVHooq7ThOkdBHSpXqqH1UDqULCmPTL1MTekDp5TZFLvSIaNSHzDYoKaRNL0pw3L9AICVyY5Cm5e0CJldQzIeITpFnIbJM2KqOiSYV2imgxpW0sgB+4KHn9mkCOBGmVpXpf0WwcRO1xVkkW7Pg3m8wsrOaJH0prIel/CCWmF9qY+eLh0GhrAopSV1ZIreO85UZrD6eR7w+A35++H/CKFV4VbvAT4fZKueFoeoVlDq1T9BC/P0Jgp/LYL+egYWuna6hb5cIxz8Gyg2FbYGUq5E/NmiICbKzbZXRoI+LeTn+AKeoHWIDfUWUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/0fc4286ed1e8892304e921faabd6844a/e93cc/Card-Variations%402x.webp 300w", "/static/0fc4286ed1e8892304e921faabd6844a/b0544/Card-Variations%402x.webp 600w", "/static/0fc4286ed1e8892304e921faabd6844a/68fc1/Card-Variations%402x.webp 1200w", "/static/0fc4286ed1e8892304e921faabd6844a/a2303/Card-Variations%402x.webp 1800w", "/static/0fc4286ed1e8892304e921faabd6844a/4293a/Card-Variations%402x.webp 2400w", "/static/0fc4286ed1e8892304e921faabd6844a/55654/Card-Variations%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/0fc4286ed1e8892304e921faabd6844a/aaafa/Card-Variations%402x.png 300w", "/static/0fc4286ed1e8892304e921faabd6844a/8db2a/Card-Variations%402x.png 600w", "/static/0fc4286ed1e8892304e921faabd6844a/6ca50/Card-Variations%402x.png 1200w", "/static/0fc4286ed1e8892304e921faabd6844a/4d07a/Card-Variations%402x.png 1800w", "/static/0fc4286ed1e8892304e921faabd6844a/ee4de/Card-Variations%402x.png 2400w", "/static/0fc4286ed1e8892304e921faabd6844a/9391c/Card-Variations%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0fc4286ed1e8892304e921faabd6844a/6ca50/Card-Variations%402x.png",
              "alt": "Variations we created on the onboarding card",
              "title": "Variations on the onboarding card based on if/how the user was invited.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Variations on the onboarding card based on if/how the user was invited.`}</figcaption>{`
  `}</figure></p>
    <p>{`For example, we can't be 100% sure what information we already have when someone signs up. If a new user signs up from an invite, the person who invited them may have already added their name or photo. On the other hand, a phone number is the only requirement for an invitation, and a user could be signing up on their own.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.42857142857142%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACdElEQVQ4y4VUTW/TQBDt3+XSExf+ARJ3rogPcUBIvSAKahBVItqiKqGUtCgI0jYhbhJ/xHH2e9d+zJo2cVIljTyaXXv88nbeG29xaeCDCV1Gdb26r9ZyqStrM4+t5aJKgbJgKy/8B1bgFKyMu384B+SVm1nGkSRTKGJhjINQBlJpKO1AF4QBlM8638DQH8GD0joSAldhhOssg85zKOVZSTirYMM2WO8r9HUTMhtTvSvf46sMb2l7NmGSIBiN0A+GSNIpGGNUY+FEBLH/EHvPHuFy5wHU1T4YseWV498B1MbiTW2EJ68ukbsc1tqSoc2BXMZgn7bR2HmK4N02ZLd2P6Bf/x0xXAwy6pmBsQ6OgB31zEgB3j+GHFD0DsCSAZhyS/1fiCIXN41WiNJJKYShHiYzEiljJAYdG8CMWNEFMsJ6UaqAM8YRDEOEYYwpF0i1RkyizJSAbh9AtOrQzc+QYTBnyDcxtJoDhYTn4fvnjOdDP1KZ1RsYfKwj2a1BBn0wss5GlZW2aJzEeLE7xGE7Q5GT9wiwILxcC5juT4yb3zBrn0DFw/UMbwEl2ebLaYLXHwLKE+TUQ1dGQcwVKfudQE+hf7fA49H9ojAaA29gpYhdYWFdcfNMlVNDW2hblNlPzgZRFnMLAmKCbONFIEFSmph4kkLSuhuPcXR+hvNBDxEZXqyb5eqRf/yKsHfYx2lniCieUKSYkXUkCdS+6OD52/eot44xThMCtJsBvZl3D2M8fnmF/daEjg1i5ntImtPUdIMeGs0Ozrp/EE3T9YBVYOvIsWRhP3b85ttX/UYKpZdstpFhaWzKGddl9iLdxqJ2sV8V5R9vAWIsVhtfnQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/49ffa2e198b1205cbfb701e95d6a27aa/e93cc/Card-Illustrations%402x.webp 300w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/b0544/Card-Illustrations%402x.webp 600w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/68fc1/Card-Illustrations%402x.webp 1200w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/a2303/Card-Illustrations%402x.webp 1800w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/4293a/Card-Illustrations%402x.webp 2400w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/55654/Card-Illustrations%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/49ffa2e198b1205cbfb701e95d6a27aa/aaafa/Card-Illustrations%402x.png 300w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/8db2a/Card-Illustrations%402x.png 600w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/6ca50/Card-Illustrations%402x.png 1200w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/4d07a/Card-Illustrations%402x.png 1800w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/ee4de/Card-Illustrations%402x.png 2400w", "/static/49ffa2e198b1205cbfb701e95d6a27aa/9391c/Card-Illustrations%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/49ffa2e198b1205cbfb701e95d6a27aa/6ca50/Card-Illustrations%402x.png",
              "alt": "Illustrations we created for the onboarding flow",
              "title": "Some illustrations we created to give the experience more personality.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Some illustrations we created to give the experience more personality.`}</figcaption>{`
  `}</figure></p>
    <p>{`Starting with the card component also freed up some time to focus on illustrations. These helped lend the experience some personality.`}</p>
    <h2>{`Booking`}</h2>
    <p>{`A good appointment booking experience is mission critical for service-based businesses. Spruce's old booking flow worked well for customers, but not for employees. It turned out that one size did not fit all after all. To remedy this, we created a booking flow tailored to employees.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.714285714285715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAByElEQVQoz42S227UMBCG+9Lc8QQ8AFcV5dDdbotEWxUhFSgXCFYFhFBRKZWWsuxRaXfjxIljx07sn7HDBhZxwUi/7PGMv8x4soFf9uLkDTa3erj3cA/bOwe4v03qHOJBZx+d3gG6e0fo7h5SbB+9x0/R2T0Ksa1HT3D8qt9AHLDhV2strPObGv9jWleYzG6wiDPkhYapPMx53m+gUAaz8QxRxKCMQ8JSzCcjKG1gyhLp8BuUFHTPIuUCg6sJ5teMoDkSLgMQK6AjRymNXAFZAXDhkFMOfQOlBmQJJHQexQbzpcaCKYymSyxZAcYV0kyFft0akG4OxgX6nzkpw8fLHG/POE4+pHj9iZMSHJ8yPOszXFwxxDFDLhRE4VUGRluhJacsDcaRwvl3gYthgS+0np7z4H/9UWB6U2GRWKoeGIw43p1NcTlMKZdjEhVNhW6tQkPJFknuQtuZqFHQM4TRkXiu6awKVTx/H+PW3QHudEe4vTnEzssIK04LlPRgvspS1zQ1G1apKmhThz0XBkLWjWiyBT1s066ClH+17B1Ds29VEZRAVW1DzNI/JaSlil0jWZHvoTrAJRXSAlebdXOtfLzJ+VP/Np/3E78Z9X2dCWpTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c85476ffc479cca1690e607280c16f5f/e93cc/Calendar%402x.webp 300w", "/static/c85476ffc479cca1690e607280c16f5f/b0544/Calendar%402x.webp 600w", "/static/c85476ffc479cca1690e607280c16f5f/68fc1/Calendar%402x.webp 1200w", "/static/c85476ffc479cca1690e607280c16f5f/a2303/Calendar%402x.webp 1800w", "/static/c85476ffc479cca1690e607280c16f5f/4293a/Calendar%402x.webp 2400w", "/static/c85476ffc479cca1690e607280c16f5f/55654/Calendar%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c85476ffc479cca1690e607280c16f5f/aaafa/Calendar%402x.png 300w", "/static/c85476ffc479cca1690e607280c16f5f/8db2a/Calendar%402x.png 600w", "/static/c85476ffc479cca1690e607280c16f5f/6ca50/Calendar%402x.png 1200w", "/static/c85476ffc479cca1690e607280c16f5f/4d07a/Calendar%402x.png 1800w", "/static/c85476ffc479cca1690e607280c16f5f/ee4de/Calendar%402x.png 2400w", "/static/c85476ffc479cca1690e607280c16f5f/9391c/Calendar%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c85476ffc479cca1690e607280c16f5f/6ca50/Calendar%402x.png",
              "alt": "A view of the Heartwood calendar",
              "title": "The new calendar view turned out to be indispensable for employees.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The new calendar view turned out to be indispensable for employees.`}</figcaption>{`
  `}</figure></p>
    <p>{`The main consideration for employee booking was to start with a calendar view. On large enough screens, we opted to keep the calendar visible. Desk staff and receptionists tend to use large screens and like to see the whole team's schedule. This view makes it easy for an employee to find an available time and provider.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "115.71428571428572%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAABYlAAAWJQFJUiTwAAADaklEQVQ4y41VXW/cRBTN3wbURyQQEiriAUSfyANCQEUhBNFKVKHQgEJbEKiEkmTj9a7ttT3+Htvjj+zh3uvdrJNdCSwdzex4fO7XuXf3dNWirDeoTCfgva6anWfj8/G3fL5X1sOFvGwQZyWiRCNOS2SFoUudvEvzkt5p5Lq+JsqKCgmd8To2srdmrmuDLMvgByHiOEbXdQhVAmvq4Hxiw54voOJcDNozD2cXM0wsB+4iIgeqDaGQNS0ePPHx3n0X+49CfPDVAh/Tas8DXFg+VGqQFC2tDVRS4p8zC9bMJ+9q8t4giMubhLxxQ415UMNVRtYoa3E6LXH38zk+OvAEd79w8NKitMQpLHtBZDWloiIj1Thkjp+SygeVQUqe1E2Hpu0llItpAGeRIqSPPDIapTUcP8XlTFEOa0K1HTJvPD+RMCIqCF8IYrqYFAhDhbqqYSjHjTFi1HYjTJ0hd+zdjZDFQ9o4npIi6NIgp9w0TYO/rRzf/OTg6EWAJ79H+P6ZwpldQIU+dJGhaxv0XSOrVHpc5cfPFb78wcPhsY+DpwG++yXEq2mOk79SPDtN8etpgpOXCf44z3Dn3jle+/AV3tyf4I17l3j//hxFeUs2llvgfFZgMh9WBleR5VRVAwyFHFPeHj+PBEcvIjw6UTj+M97WYUGi1QwKeQ2uYkR5SmhlcM4ySceQT1MPeza0RZjrRgjCKJdO4X2ujZyruBBBM1mhjahCl610EocqZHxWNxtCz48xdxUJ2YEXJEIWqIzE7dN5KB0RkahTasnlcom+vyLPGlxdLQXXvbx2NUlzar1CLrIGGUw4mXoIY00hG+kS9pwfe2rj7Tuv44ruE/91P4tsypWwa7JoqA1r01JuOhGxr0q4gSZRl1goLa3W9T0uJxYeHn5LUqO+J9LRtFmNKUJlBjIhbDsRLRPNFlrAncKeNmQ0z3NUNWtWo+36bUJDJD1ZZvCkYS+4skw6BueWQ2Rwevi5kcNr2ZRm1c+NVJJ/D9NkA+5bXW4KIcS3i7LeJKvJkYywJhGs9iyjouoJ3WrtSS47Qh57sgZ7uCZej6qKirjr2Ul427vbZ2zADSuaiy7e+dTBu585eOuTOfYf+jtar/wvDO3F0/vwZ4Wvn4Y4OFZ48GOIo9/i7Xn4f6DlD6qlAPubWG5y+C/E5tNotkNnQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f700621fed566a4c0c767b8bb70c1da6/e93cc/Booking-TX-Sidebar%402x.webp 300w", "/static/f700621fed566a4c0c767b8bb70c1da6/b0544/Booking-TX-Sidebar%402x.webp 600w", "/static/f700621fed566a4c0c767b8bb70c1da6/68fc1/Booking-TX-Sidebar%402x.webp 1200w", "/static/f700621fed566a4c0c767b8bb70c1da6/a2303/Booking-TX-Sidebar%402x.webp 1800w", "/static/f700621fed566a4c0c767b8bb70c1da6/4293a/Booking-TX-Sidebar%402x.webp 2400w", "/static/f700621fed566a4c0c767b8bb70c1da6/55654/Booking-TX-Sidebar%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f700621fed566a4c0c767b8bb70c1da6/aaafa/Booking-TX-Sidebar%402x.png 300w", "/static/f700621fed566a4c0c767b8bb70c1da6/8db2a/Booking-TX-Sidebar%402x.png 600w", "/static/f700621fed566a4c0c767b8bb70c1da6/6ca50/Booking-TX-Sidebar%402x.png 1200w", "/static/f700621fed566a4c0c767b8bb70c1da6/4d07a/Booking-TX-Sidebar%402x.png 1800w", "/static/f700621fed566a4c0c767b8bb70c1da6/ee4de/Booking-TX-Sidebar%402x.png 2400w", "/static/f700621fed566a4c0c767b8bb70c1da6/9391c/Booking-TX-Sidebar%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f700621fed566a4c0c767b8bb70c1da6/6ca50/Booking-TX-Sidebar%402x.png",
              "alt": "Examples of how the booking flow is delivered via the sidebar",
              "title": "The calendar sidebar contains the whole booking flow on mobile.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The calendar sidebar contains the whole booking flow on mobile.`}</figcaption>{`
  `}</figure></p>
    <p>{`We found that service providers tend to book their own appointments on their phones. This presented a challenge. We had to make sure that no interaction relied entirely on the calendar being visible. To solve this, we introduced some new components. Some of these turned out to solve problems outside of booking.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB+ElEQVQoz42STWtTQRSGrwjib3HjRgR3QjeCOxdFsDVSEqRJI6Zqa7RZxK+21hipgptus1CUqkVraygiKCpq6cI0UVOTpvm6+ei9uU1yk2vyOIlJQcQmB4YZDvM+c945R6IV9Xq9uVerVaZv3mbEPsyYY5Rhi403/iVeLPjpH7By2nYOk3kIl3ucSqXyl7YRUvtQq9Wa+3oiya5jg0jHz7LH4kIyOTnhncHne8h5p5uJW3eZ9NxjfGqaVFreAdhKJjNZ3B9CXA2lmYioXJcNfIEIyY0E5XJ1W2iI93O5/P+B7WQ+v0ldU0EvQkkTSh05FmPlWxhFN9gqilU2SGVUst0AG5cSeQWtVEI3jGYuvB7n62qQtUiYaDSMVsgTi64hZzLdATObKiUBbKxGRITd5eUvrH56z4r/FZ/fvWZpYQ5ZlrsDJoXtwlYRrVii0aofosJgMCh+QKVaUPj49Anzs4/ZVNTOwJyAURaV1YXdX3+akIkLYOg7mnikLVWVDn/Y7nJCzjLyNoArmObKT4ULqRp3AhsUFQXPpIeeg4eY8Xq33XScw0g8KWbQgXTqEtLQDST7FEfuP2BxfpHDPUfZLe3lwL79OMeukUilW9odLOu6jvuyG/NJM1azFVNvPy+fPefR7By9fRYGz4zSN2DD7rgo5rL8T4W/ATQpC77c7YCsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1f867731a3ccc98de12c191e1e35a1aa/e93cc/Guest-Booking-Before-and-After%402x.webp 300w", "/static/1f867731a3ccc98de12c191e1e35a1aa/b0544/Guest-Booking-Before-and-After%402x.webp 600w", "/static/1f867731a3ccc98de12c191e1e35a1aa/68fc1/Guest-Booking-Before-and-After%402x.webp 1200w", "/static/1f867731a3ccc98de12c191e1e35a1aa/a2303/Guest-Booking-Before-and-After%402x.webp 1800w", "/static/1f867731a3ccc98de12c191e1e35a1aa/4293a/Guest-Booking-Before-and-After%402x.webp 2400w", "/static/1f867731a3ccc98de12c191e1e35a1aa/55654/Guest-Booking-Before-and-After%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1f867731a3ccc98de12c191e1e35a1aa/aaafa/Guest-Booking-Before-and-After%402x.png 300w", "/static/1f867731a3ccc98de12c191e1e35a1aa/8db2a/Guest-Booking-Before-and-After%402x.png 600w", "/static/1f867731a3ccc98de12c191e1e35a1aa/6ca50/Guest-Booking-Before-and-After%402x.png 1200w", "/static/1f867731a3ccc98de12c191e1e35a1aa/4d07a/Guest-Booking-Before-and-After%402x.png 1800w", "/static/1f867731a3ccc98de12c191e1e35a1aa/ee4de/Guest-Booking-Before-and-After%402x.png 2400w", "/static/1f867731a3ccc98de12c191e1e35a1aa/9391c/Guest-Booking-Before-and-After%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1f867731a3ccc98de12c191e1e35a1aa/6ca50/Guest-Booking-Before-and-After%402x.png",
              "alt": "Side by side comparison of changes to customer booking",
              "title": "The old customer booking (left) and the new experience (right).",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The old customer booking (left) and the new experience (right).`}</figcaption>{`
  `}</figure></p>
    <p>{`Customer booking stayed as it was for the most part. The key change was how a user selects a service to book. The previous iteration had two separate steps for service and provider selection. The new flow makes provider selection a subtask within service selection. This turned out to be more intuitive to customers, and was a performance gain for the app.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.28571428571428%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEVklEQVQ4y2WV2VPbVhTG+R/bh9JpCATCYpaYJgRohpmkUAiEfTU7AQIYhyQNlAYaQvvQmfahGYYAwcar5FWWbMubvMHXcy9jgsmdOaM7V1efvnPP+UlF5+fnYGNt/TVKymtRVXsft+7o0NM3gpPPZtTUN+FuTSPKq/X8HlvrHRhDaUU9v/dDaQ1WjBtcg2kVscnFxQUM08/R/KgdT3tH0PVsGAMjk/h9Zw9NrU/Q8XSARMbxc2cf3u1+QGfPIFrbOvC4owed3YOYX1xF3hgXTKcz8PglaKkM8kNLZeF0uRGKRHF9iG4vwpEI0pkMN8JGIpGEpqWuC6ZhdQgIRGMQ5BDUWAqiNwjzmQ1uSYErHIGsJiDJUZxZHRBFDyJqFCrtj5OY1+tHKpUudOhwipDDKkJqDJnsOXyBEKz0sI+cyxGVO4rGNFjtTkh+H9KpJDJpjYtIklQomKHNVrsLQTlM6UR5umxutti4cFLLIUERDCW4oNUlw+ZJwuaOQwzm4BAl0kgXpuwQvIjE0ggoCajxS8H9f8yoGzhF67QLLVNO1A1a8Ne/FrzYPED/iyOMrx+hb/kIv74/pMpmCwXtTiZIxZESUNQUBE8QpxYHXF4VfiUJv5zk85NTK1bXjDCum7Bueok14zrebm4jm819EUylUnTgKl9IX1afXGZ4RW8OlyBic+s37Ozu8ni3s4O9D/skeM1hLpfD0soGhsbmMGpYxPDEAiaml7D7/k9am8HU7DImKdh85499PGhuo6a+j4bGZlTq9OjuHbpqoaJ8QxpNb3CbSNE1NBEFdegfGsfnU8slKbpGih/5nK0NUtMzcuobW1FepYfp1dsvpOQFN95s8U11+hbcqbyHodEp6jk79A8e0UsektgDLnBmtWF4bBoVNXrU3mtGWWUDXr7eLESPTWbmlvG4vQcDwwYeY4ZZbBN6eRwHR6cJuSGechdd2550o394ktIdxdzzG+ixoqhqIWJsg1NwQwmrX6GnhCLInV9crYUJBqZRIOgPSLx9tl+ZIPm8HC2GnqLGEYlrcJOIT4nCanNCEL0ISDIElwNySIXN4eK0FbZNUOauFn9ph2C1IEkonXH0grAT58dmG7yBIGxElD8g87DaBRKMkGsfN1PQ2AIBz67Z9GU/sbTs9GaBNjsFD/WfB6LHz8VFt4cMKJCVEG9oll1BytcF2UeAjWQiyt2o0TiPeEJDIqnxl5hPTqAoytVet1f62qHoCSCmXVDaFzDuK5jddMJuPcOng0ME6DjYHibKzstNDpk7TdMICiqeeCNl9rX57+MBDg6PcHR8jK29jzBt/Q2P202fqMyNKtPDmctjydc5QoXTrqfMsOnq7kdZhQ7VtY3Q1TXgYctPHL1n9P8Yn5wn/Bap76b4mmFmCSMT8xgxLHAcl1c3OL4F6C2vmFB8qxIV1Y0oLqniTXz46Zj/uL6/XU1RhRL6ebG19s5efFtcxlH95rtSLCytXfXu//xO6iBdPzHQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8e9f246904eac9549e2f7a9cdd414716/e93cc/Guest-Booking-Lazy-Registration%402x.webp 300w", "/static/8e9f246904eac9549e2f7a9cdd414716/b0544/Guest-Booking-Lazy-Registration%402x.webp 600w", "/static/8e9f246904eac9549e2f7a9cdd414716/68fc1/Guest-Booking-Lazy-Registration%402x.webp 1200w", "/static/8e9f246904eac9549e2f7a9cdd414716/a2303/Guest-Booking-Lazy-Registration%402x.webp 1800w", "/static/8e9f246904eac9549e2f7a9cdd414716/4293a/Guest-Booking-Lazy-Registration%402x.webp 2400w", "/static/8e9f246904eac9549e2f7a9cdd414716/55654/Guest-Booking-Lazy-Registration%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8e9f246904eac9549e2f7a9cdd414716/aaafa/Guest-Booking-Lazy-Registration%402x.png 300w", "/static/8e9f246904eac9549e2f7a9cdd414716/8db2a/Guest-Booking-Lazy-Registration%402x.png 600w", "/static/8e9f246904eac9549e2f7a9cdd414716/6ca50/Guest-Booking-Lazy-Registration%402x.png 1200w", "/static/8e9f246904eac9549e2f7a9cdd414716/4d07a/Guest-Booking-Lazy-Registration%402x.png 1800w", "/static/8e9f246904eac9549e2f7a9cdd414716/ee4de/Guest-Booking-Lazy-Registration%402x.png 2400w", "/static/8e9f246904eac9549e2f7a9cdd414716/9391c/Guest-Booking-Lazy-Registration%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8e9f246904eac9549e2f7a9cdd414716/6ca50/Guest-Booking-Lazy-Registration%402x.png",
              "alt": "Flow showing lazy registration in customer booking",
              "title": "We moved signup/login to the end of the booking process for customers.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`We moved signup/login to the end of the booking process for customers.`}</figcaption>{`
  `}</figure></p>
    <p>{`The other crucial change to customer booking was to make personal info the last step. This “lazy” booking makes it possible for potential customers to browse without committing. Our theory was that it would also result in a higher rate of successful bookings.`}</p>
    <h2>{`Visual Identity`}</h2>
    <p>{`Heartwood began with a focus on the core Spruce product. As we moved forward, there was some divergence between product and brand design. We decided to use Heartwood's core principles to refresh the Spruce visual identity. Our goal was to make the end-to-end experience more cohesive.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.285714285714285%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACxklEQVQozzVSS0wTYRDeKxcBKbTbLrRst5UQowcNifFgYuLJEA8GAyEKMUhAIyivQpGnRYqALaI8NCBqTHiVUlpaoJCovJEuT0tB9GjiQRIM0YSYfM7WcpjMzDffzDe78zPCiBO8YwSxww7E2IehsDugplzCeKqF9Q/A7PejeWsLUYM26AnTUk0IeSk/4p92ucAII1SkREd2gooSQQh5dsiOnMUltO/soI0sm2KFzf5/qMQLDZL6LrjdOOceBZPodEHrGoVAJilqaVOJqKdcRQMzZudgEFdg8InBWGUbCoof8QQnidNmiZKnnJHA+L5+8L190HnGoJ+cgjDqBt8/CB15zuOB1u2BMOEFR17v9UIgTDc+EeRpSZQnEZ4WE6ifSaRV2fxCRF5LR8ydfCjuFyPW0oKIKymIlvKiUsTczoOyvBJKgxHyghJwzVZwdQ2ISrsBZdVDHLt0GcdTr0NRYgQTT4qqrldgW1rBUpE1lCOuvRMawrgnVsiJxFZUQ05CnLkxOEDT1gG+yYK4GhMS6Gjq2kfgCFcTnzlfWYOzdWacInX2XhHkBLKEcdQgp+2kA8ly7gY/TU4cWVYuWFM9Iq2tkDU0IbzaBJnlKVS0EO9wgsksNeJWdxeM9WZUFhYjqdaE6Nw8KAoNiM7IgrrjJSKupkJZ/xhs2QOEp9CvyStAZrEB1hedyCmvwMWb2VCWVSCBDsM0LsxiZt2HP9sb8K5+QvLUJHi6sPS22JDXSUegWEPG0SHeLS/gcNePv98CwFc/9qjXt7kCkeYwq2vLOCQgsCGiYeYDksfHcIaeQdqkF1Uf3yOJ4tjuHmh63uAkvdnWuWn83l7H3tYaROp9Nj+Dzxs+/Aqs4SdhzH5gHQdE8IqLeD4/jXQatLsp4u3ibLD5NX0BR09IPWBD79Ic8GUTP/yrOKA+C9XDBofgWJ7HPg38Tvg/NoU2wOg1dtwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d13986f88f473e6bc4c7a7d6c0d35eaa/e93cc/Brand-Expression%402x.webp 300w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/b0544/Brand-Expression%402x.webp 600w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/68fc1/Brand-Expression%402x.webp 1200w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/a2303/Brand-Expression%402x.webp 1800w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/4293a/Brand-Expression%402x.webp 2400w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/55654/Brand-Expression%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d13986f88f473e6bc4c7a7d6c0d35eaa/aaafa/Brand-Expression%402x.png 300w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/8db2a/Brand-Expression%402x.png 600w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/6ca50/Brand-Expression%402x.png 1200w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/4d07a/Brand-Expression%402x.png 1800w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/ee4de/Brand-Expression%402x.png 2400w", "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/9391c/Brand-Expression%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d13986f88f473e6bc4c7a7d6c0d35eaa/6ca50/Brand-Expression%402x.png",
              "alt": "An illustration with the words “The Future of Brick and Mortar” and the new Spruce logo",
              "title": "A brand expression piece created during visual explorations.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`A brand expression piece created during visual explorations.`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.857142857142854%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAsUlEQVQoz61RTQ/CIAzl//8qL0u8LpsxuxrPcuAbGU9EWWDAvNikadr3+pq2BMG893DOxdjzX3hykifJQobc9viHgyaH5OT9tCReCPoOryWYAERHRS7xuq8SXL9xURR3y7da4iT8pjVmxoqe7g0FFzjNI87L9c2Ot8pxozSGccIwXUAftDpVJWithWQCRqqgt1YrP8O3BedQQsIY2xfc7tP5XuvTec/hU3q1owHNlf/hL5WQF2uV1XUcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/3e941052ac8ee26372dad0282df08902/e93cc/Logo-Changes%402x.webp 300w", "/static/3e941052ac8ee26372dad0282df08902/b0544/Logo-Changes%402x.webp 600w", "/static/3e941052ac8ee26372dad0282df08902/68fc1/Logo-Changes%402x.webp 1200w", "/static/3e941052ac8ee26372dad0282df08902/a2303/Logo-Changes%402x.webp 1800w", "/static/3e941052ac8ee26372dad0282df08902/4293a/Logo-Changes%402x.webp 2400w", "/static/3e941052ac8ee26372dad0282df08902/55654/Logo-Changes%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/3e941052ac8ee26372dad0282df08902/aaafa/Logo-Changes%402x.png 300w", "/static/3e941052ac8ee26372dad0282df08902/8db2a/Logo-Changes%402x.png 600w", "/static/3e941052ac8ee26372dad0282df08902/6ca50/Logo-Changes%402x.png 1200w", "/static/3e941052ac8ee26372dad0282df08902/4d07a/Logo-Changes%402x.png 1800w", "/static/3e941052ac8ee26372dad0282df08902/ee4de/Logo-Changes%402x.png 2400w", "/static/3e941052ac8ee26372dad0282df08902/9391c/Logo-Changes%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3e941052ac8ee26372dad0282df08902/6ca50/Logo-Changes%402x.png",
              "alt": "Side by side comparison showing how the logo changed",
              "title": "Changes to the logo.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Changes to the logo.`}</figcaption>{`
  `}</figure></p>
    <p>{`The old logo always felt a bit off, for reasons the team found hard to vocalize. Looking at it through the lens of Heartwood's Humanity principle made it clear. The logo was frowning. The solution was to make it smile.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADaElEQVQozx2S6U/TBxyHf3/FsmTLlh0iAhqUS6GtQKEgcrUFSlpADgUKlMps6QHjZhOniWxzbi8Wl2WEiYoIpdyXRVHsWmxLLUF2OHQRsszFiUt2PGv24nnxefPJN9/PI7ywJPLUnIGq3ou0ap1U7UMU1W6W5Un48kS4chJYCbI53M/a6DUKrB1kmdtRNHWRrLPwWoycN+KUvHlQwSuRmQjPW2X80pKLosZDRfuPVHY9IuuEC4ciBZcqGd8xGYHSFB7bviYw2s/x7jOUdH1E+QcfomhsIkKaT1S6mui0YsIlBQg7S2fZWuhFeXKN+p5N9Gc2kVWsUBMjwySS8PNEGU8dVWwv63nuszB524590caW2xDMDTy7X8/W8kl8Q9+wOnQZYWOqhxXbWXLrXCSWe8jSr6PQudGlqrBmy/GNa3kwW0tgvJyfbtYyMTsY5Dp8b+HhzAnW5yrhBwtPxvt4Yh9ACBUr2Sct5O34QkIkasYc0zhci9y8M8HYzDDSbAviZD2N6Vm0ZOdiysjHmpnLn149nrEKXLYKWGtgpzuLl60ZCGESFQdkJewSq/8vvOWe5dLVPr4a7Of2fQcxqW1ExBk5Ls7j1BEN9dI89NJMNh117PgN/L76Hr85dTyyprJplCKsjXyL88oAcr0XheEBI3OTXLrSx5dBhuemUJsD5J0KoDH6ydE62RWpY/f+aiIT9OxNqENhakHd1klRcxtKoxXhsS2ow/UBKs1+ykyr2BammFoaZ8k9yfzdGcqsq5RaApQ1r1PQ4OX1CD2FFefJL+3l1T1VqIL6HOvsoajjNMrmDgT3kf18Jw1nURTGlDSGfNP7aDo72V4x8Kuzger4NFQhYorCxKj3pRB72EhB2XnkxecQycxsTJ/jD9cnPFv+mO2lXoS78W/hKTkadC2DRfE7ZNSayDQ04Z/WsjGjRRt1kOYcNW1KDZrQOGITDcSmWIhKMpMgM/HCeQG/vQfPyGnwXUS4J3oXf52GNZ2aufjdiKQG4tKbkclbSEwzoA49RE9pDd3F1VRGJ3JtdJCrEze4bB/CPmfjL+8XLPS3M9/fwd+ezxCcSXtYDpa6gtfNS8I5LG1EcrSd+PRWDoiNFO8VUxoWR1FINDWHUhiduRH882hwPBvzd8bZWfmcfz0X+Md7kZeuT/kPAqFkh3mdSQwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f321b0a607a29cf9014160d00789b986/e93cc/Color-Exploration%402x.webp 300w", "/static/f321b0a607a29cf9014160d00789b986/b0544/Color-Exploration%402x.webp 600w", "/static/f321b0a607a29cf9014160d00789b986/68fc1/Color-Exploration%402x.webp 1200w", "/static/f321b0a607a29cf9014160d00789b986/a2303/Color-Exploration%402x.webp 1800w", "/static/f321b0a607a29cf9014160d00789b986/4293a/Color-Exploration%402x.webp 2400w", "/static/f321b0a607a29cf9014160d00789b986/55654/Color-Exploration%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f321b0a607a29cf9014160d00789b986/aaafa/Color-Exploration%402x.png 300w", "/static/f321b0a607a29cf9014160d00789b986/8db2a/Color-Exploration%402x.png 600w", "/static/f321b0a607a29cf9014160d00789b986/6ca50/Color-Exploration%402x.png 1200w", "/static/f321b0a607a29cf9014160d00789b986/4d07a/Color-Exploration%402x.png 1800w", "/static/f321b0a607a29cf9014160d00789b986/ee4de/Color-Exploration%402x.png 2400w", "/static/f321b0a607a29cf9014160d00789b986/9391c/Color-Exploration%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f321b0a607a29cf9014160d00789b986/6ca50/Color-Exploration%402x.png",
              "alt": "A color exploration showing the Spruce logo in different colors",
              "title": "Color explorations based on contrast of temperature and saturation.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Color explorations based on contrast of temperature and saturation.`}</figcaption>{`
  `}</figure></p>
    <p>{`Our new color palette was a bit spare when it came to expressiveness. We devised an approach that would focus on how we create contrast, rather than on specific colors. This enabled our creative team to try lots of ideas and push the envelope. At the same time, we had enough parameters to avoid visual chaos.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABmElEQVQoz61RWUtCYRC9fy6C/kFBRBhJYlIUvRQVrRS2vKTm1mKRBa5Ftj60oNACpQapgS9q+pTXfT/db+pesKCnDgwzd+58M3POcBDQaDSYQ61Wk+KfqNfr+AviO04MCoUCrq5vEIvF6TsajSIQCFLhx0eacqlUCrp1A8wbm1jXG7Cm0WFicgp+f0AayomT44kE1EsrMJs3oTcYodHqsLCoht3uxOtriGpmZucxNDyCltY2yHrkGBgcgsWyS0NFkxomhIZu9xFc7kN4PKew7h9gz7qPY88JeJ5HuVwmBl6vD06XGyenZ7A7nMhksk2ScPhH0IZsMtOPIZfLkWcbZbPZXw+q1Spi8bh0gGKxSDHzFeEfbfieTGJpeRXnF5fkI5E30mp6Zg7B4Auub26pCcPD4yNJkU7zCIXDuLu7p4PYbA5JZ47PZNDe0UkCd3XL0KdQQqFUoV81iNGxcfTKFXh6eqZirU4Pg9GMre0dwSzfBzTBJHivz/fVkImZz+eJNqMp0mXG8pVKpYkio10qlSjPjMVMNrHmExCwgMBDsIRzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/e00bc9cb388ac9525e4ac4640f2aa847/e93cc/Words%402x.webp 300w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/b0544/Words%402x.webp 600w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/68fc1/Words%402x.webp 1200w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/a2303/Words%402x.webp 1800w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/4293a/Words%402x.webp 2400w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/55654/Words%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/e00bc9cb388ac9525e4ac4640f2aa847/aaafa/Words%402x.png 300w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/8db2a/Words%402x.png 600w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/6ca50/Words%402x.png 1200w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/4d07a/Words%402x.png 1800w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/ee4de/Words%402x.png 2400w", "/static/e00bc9cb388ac9525e4ac4640f2aa847/9391c/Words%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e00bc9cb388ac9525e4ac4640f2aa847/6ca50/Words%402x.png",
              "alt": "Custom lettering of the words beautiful, fast, intuitive, and scalable",
              "title": "Custom lettering of the Engineering team's values.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Custom lettering of the Engineering team's values.`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.142857142857146%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAAA5ElEQVQoz5WQuQrCQBCGfWR9Czs7IZ2SSlDE1gNExVtTRAwGEhULCTGFV4y6u797BItgEQeGOXb3m38ng4RRymScOwHq0wPPGNbHFQiecKwArh3Ic8aY9KRlkg0SAzsrD1pnK0Zgup/gjQiW4WNj+gpIUwKFInXtxdNQqb7eRRthFEr/KkQKIKNUxpM7xG5RBXu+0c7mgMsZ+WIZBU1XPyEkrUIxXSnlz1R9u8n6/CC4Psh/O6SxwsFsDL1REw00SxpeHOrZQ+7jGEj/A5q2hdaoJ4HzRgUkirBbdrE3+t/V/AJ+AJuBHCLFNXR6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b0ff665a8f7e2e338c2d12fe8254dc47/e93cc/Avatars-Sample%402x.webp 300w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/b0544/Avatars-Sample%402x.webp 600w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/68fc1/Avatars-Sample%402x.webp 1200w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/a2303/Avatars-Sample%402x.webp 1800w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/4293a/Avatars-Sample%402x.webp 2400w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/55654/Avatars-Sample%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b0ff665a8f7e2e338c2d12fe8254dc47/aaafa/Avatars-Sample%402x.png 300w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/8db2a/Avatars-Sample%402x.png 600w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/6ca50/Avatars-Sample%402x.png 1200w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/4d07a/Avatars-Sample%402x.png 1800w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/ee4de/Avatars-Sample%402x.png 2400w", "/static/b0ff665a8f7e2e338c2d12fe8254dc47/9391c/Avatars-Sample%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b0ff665a8f7e2e338c2d12fe8254dc47/6ca50/Avatars-Sample%402x.png",
              "alt": "Samples of avatar explorations.",
              "title": "Samples of avatar explorations.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Samples of avatar explorations.`}</figcaption>{`
  `}</figure></p>
    <p>{`Our default avatars presented an opportunity to bridge the brand and product experience. We had been using one default for every new user, which was easy to put in place, but caused some problems. Using one default for everyone made it hard to differentiate between new users. To solve this problem, we decided to create a set of placeholder avatars. We gave ourselves some parameters to work within:`}</p>
    <ul>
      <li parentName="ul">{`Avatars have to work on light and dark backgrounds`}</li>
      <li parentName="ul">{`Avatars should be inclusive and make users feel welcome`}</li>
      <li parentName="ul">{`There shouldn't be a reason that someone would want to pick a specific avatar`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA+ElEQVQY03WQTU7CUBSFWYhLcQmuwQXo2IQRYxk6c+icgQ4IJBoMJmgjkgCJoBRTKUqhqfiaCvLTvn6WR2k0wTt5J+fmfO/kpognkKF6qy9jdg4u2M3c8GA2ObnMUnktU8x1OE6X0Updzg6PyO7t897WVUZKucGQ2ogwDBMzp/WpPDurb3gatZn6HuJjRuN+QBCA2WzxeH3LtuwfoIxbnl51KdYtpTXjLgJ+4YkFpfy6ka7VqJ4X1rmo3f/AeNHoCXrORGnLtfDlksU8oG8I5YmhzVA3ktxWoLpFvGi9uVif30rb3ii6r4+/lAxMV3meM8Y2zKTh7/kBP1h2ziUwzzsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1927a2d776946dcca2651ec445e5b42f/e93cc/Avatars-01%402x.webp 300w", "/static/1927a2d776946dcca2651ec445e5b42f/b0544/Avatars-01%402x.webp 600w", "/static/1927a2d776946dcca2651ec445e5b42f/68fc1/Avatars-01%402x.webp 1200w", "/static/1927a2d776946dcca2651ec445e5b42f/a2303/Avatars-01%402x.webp 1800w", "/static/1927a2d776946dcca2651ec445e5b42f/4293a/Avatars-01%402x.webp 2400w", "/static/1927a2d776946dcca2651ec445e5b42f/55654/Avatars-01%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1927a2d776946dcca2651ec445e5b42f/aaafa/Avatars-01%402x.png 300w", "/static/1927a2d776946dcca2651ec445e5b42f/8db2a/Avatars-01%402x.png 600w", "/static/1927a2d776946dcca2651ec445e5b42f/6ca50/Avatars-01%402x.png 1200w", "/static/1927a2d776946dcca2651ec445e5b42f/4d07a/Avatars-01%402x.png 1800w", "/static/1927a2d776946dcca2651ec445e5b42f/ee4de/Avatars-01%402x.png 2400w", "/static/1927a2d776946dcca2651ec445e5b42f/9391c/Avatars-01%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1927a2d776946dcca2651ec445e5b42f/6ca50/Avatars-01%402x.png",
              "alt": "First round of avatar exploration.",
              "title": "First round of avatar exploration.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`First round of avatar exploration.`}</figcaption>{`
  `}</figure></p>
    <p>{`The first round of exploration used rough line work and some textured backgrounds. This approach created a decent set of avatars, but felt a bit risky. The line work approach seemed like it could become gendered as the set grows.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA7klEQVQY04WQQUrDUBRFuwV34AK6BMHlCO6gI4eCIHVSpTixnYg4sYWOFATRgR2kgVDBjDQp0pq0jWny89Py/5F+qqYI+kaHe3mHxyuxGq0VegnC46Gxg319iNe65HxrG7t6xG5ln43NMqcXHW7uLOpnV3yI+WpXf2ko/Qj1d6GGjxC75Kmk32iSDXyevRG1Zps4EUzSHH88+7W3JsQUyuC7e0voO4bt4xPmYYg3DNg7qJnMfwt4cl//FhYLOX1hkYaGR5aFEoI4Fdx3bZMlQjKO/ruw8IssGrDIIsOB46ByiZA53V7fZEueFITF+QTpQXW5ICxYvwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7727f2e76ecefa52497d2edcaf9950f3/e93cc/Avatars-02%402x.webp 300w", "/static/7727f2e76ecefa52497d2edcaf9950f3/b0544/Avatars-02%402x.webp 600w", "/static/7727f2e76ecefa52497d2edcaf9950f3/68fc1/Avatars-02%402x.webp 1200w", "/static/7727f2e76ecefa52497d2edcaf9950f3/a2303/Avatars-02%402x.webp 1800w", "/static/7727f2e76ecefa52497d2edcaf9950f3/4293a/Avatars-02%402x.webp 2400w", "/static/7727f2e76ecefa52497d2edcaf9950f3/55654/Avatars-02%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7727f2e76ecefa52497d2edcaf9950f3/aaafa/Avatars-02%402x.png 300w", "/static/7727f2e76ecefa52497d2edcaf9950f3/8db2a/Avatars-02%402x.png 600w", "/static/7727f2e76ecefa52497d2edcaf9950f3/6ca50/Avatars-02%402x.png 1200w", "/static/7727f2e76ecefa52497d2edcaf9950f3/4d07a/Avatars-02%402x.png 1800w", "/static/7727f2e76ecefa52497d2edcaf9950f3/ee4de/Avatars-02%402x.png 2400w", "/static/7727f2e76ecefa52497d2edcaf9950f3/9391c/Avatars-02%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7727f2e76ecefa52497d2edcaf9950f3/6ca50/Avatars-02%402x.png",
              "alt": "Second round of avatar exploration.",
              "title": "Second round of avatar exploration.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Second round of avatar exploration.`}</figcaption>{`
  `}</figure></p>
    <p>{`The next round went more abstract and punched up the background textures. The big grins on these felt a bit cheesy and the set was too homogenous.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA7UlEQVQY03WQwU7CQBRF+Uf/wS9w4dYvMCbGtW5cGENcsNGFC00kmogJgWkNaITGQdsMFkoHytAeZUoJDfFt3s19uWfevAqrStPM9ueuYuegyu7xLR/ild7pIX6jzlnLYe/6hmrb5eRcsH/0iPc1ybNZVmCoFCLbMGtPfequv3QJ3SbEEf1xxJXjEJuEnhzT7ASrXDlbAhYvXdx1eBDSar/1ArMpgdZctoX1xPsP943Bert/gcXA9YZIlX9HSw/MHG0MTpBvNRzN+PyebOVKwM1bvMkQP9RWT9UfZGFIFildpaw3ihIGQbx1qmX9AgbYezLqfi8jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f88191dcdb34c1dbc90829c60f540252/e93cc/Avatars-03%402x.webp 300w", "/static/f88191dcdb34c1dbc90829c60f540252/b0544/Avatars-03%402x.webp 600w", "/static/f88191dcdb34c1dbc90829c60f540252/68fc1/Avatars-03%402x.webp 1200w", "/static/f88191dcdb34c1dbc90829c60f540252/a2303/Avatars-03%402x.webp 1800w", "/static/f88191dcdb34c1dbc90829c60f540252/4293a/Avatars-03%402x.webp 2400w", "/static/f88191dcdb34c1dbc90829c60f540252/55654/Avatars-03%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f88191dcdb34c1dbc90829c60f540252/aaafa/Avatars-03%402x.png 300w", "/static/f88191dcdb34c1dbc90829c60f540252/8db2a/Avatars-03%402x.png 600w", "/static/f88191dcdb34c1dbc90829c60f540252/6ca50/Avatars-03%402x.png 1200w", "/static/f88191dcdb34c1dbc90829c60f540252/4d07a/Avatars-03%402x.png 1800w", "/static/f88191dcdb34c1dbc90829c60f540252/ee4de/Avatars-03%402x.png 2400w", "/static/f88191dcdb34c1dbc90829c60f540252/9391c/Avatars-03%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f88191dcdb34c1dbc90829c60f540252/6ca50/Avatars-03%402x.png",
              "alt": "Third and final round of avatar exploration.",
              "title": "Third and final round of avatar exploration.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Third and final round of avatar exploration.`}</figcaption>{`
  `}</figure></p>
    <p>{`The third round used a more photographic approach. We toned down the smiles on these and found that we could generate new ones quickly as an added bonus.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.714285714285715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABwklEQVQoz41RXWsTQRTtn/bNX+AP8MknQ5ImlaRgwZaioIiNoKC02mAgHyRNq9vd2Z3v2dnd451JayL2wYWzZ4a5c+aee/aUKSG1w8npWzxv7aPV7qPbG6LVGRCGaHeH6PWHODg4RI+w3xug/+II3f7LTU17gOPX7xF0AvbCTyiLsmrga/zXR+VYrBIkKae7JYxrYlPyXpBThZAGkxXD+lcOW1ZQQiFPEmhj4UqPK5ZBa4WmqVFwhcl0ieufGZJM4JbJKPinw7AJXRpXQ1p6zVTENegodm7LGrmocJM6rBNHIhrz1S0JKbDCIM011K6gCO1qi8lS4cN5EfF5zIlzvPnE8O5LHnEyyvDqLMPllCFNM0hypeheIczfHQbBcLC8MbiYKnyfKXwjHl1wYonxXOEq8UgY2ZXAj0WB0dcVxjNGtQXma0Fioakdy5Isc1WDiYYYhCoWAQ2BhIQDlz5ujz+mePR0giedBR4/m6Fzeg3rNjpbyyTonKcAKniaW2BtPUqKPoQUxKSm2eqa3FCyFFZwpQlSmYdDCQVhuNpsDo31MWFLDzHuKZg7cEt7TWw2vDvD+0W0/QDEHSuzxT91ZqvxG2yC7/5h/X7yAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/04dea48f686738a44c32e41328fc6dcd/e93cc/Avatars-in-Context%402x.webp 300w", "/static/04dea48f686738a44c32e41328fc6dcd/b0544/Avatars-in-Context%402x.webp 600w", "/static/04dea48f686738a44c32e41328fc6dcd/68fc1/Avatars-in-Context%402x.webp 1200w", "/static/04dea48f686738a44c32e41328fc6dcd/a2303/Avatars-in-Context%402x.webp 1800w", "/static/04dea48f686738a44c32e41328fc6dcd/4293a/Avatars-in-Context%402x.webp 2400w", "/static/04dea48f686738a44c32e41328fc6dcd/55654/Avatars-in-Context%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/04dea48f686738a44c32e41328fc6dcd/aaafa/Avatars-in-Context%402x.png 300w", "/static/04dea48f686738a44c32e41328fc6dcd/8db2a/Avatars-in-Context%402x.png 600w", "/static/04dea48f686738a44c32e41328fc6dcd/6ca50/Avatars-in-Context%402x.png 1200w", "/static/04dea48f686738a44c32e41328fc6dcd/4d07a/Avatars-in-Context%402x.png 1800w", "/static/04dea48f686738a44c32e41328fc6dcd/ee4de/Avatars-in-Context%402x.png 2400w", "/static/04dea48f686738a44c32e41328fc6dcd/9391c/Avatars-in-Context%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/04dea48f686738a44c32e41328fc6dcd/6ca50/Avatars-in-Context%402x.png",
              "alt": "New avatars in context.",
              "title": "New avatars in context.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`New avatars in context.`}</figcaption>{`
  `}</figure></p>
    <p>{`We auditioned this round in the interface and decided to go with it. This approach checked all the right boxes and we felt confident that we could add to it in the future.`}</p>
    <h2>{`The Future of Heartwood`}</h2>
    <p>{`I left the team at Spruce Labs with some free ideas of how to grow and evolve Heartwood in the future.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.714285714285715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB0klEQVQoz6WSW08TURSFefFJTQwgBdpO27nQzqVzOZ0ZSmqLLSTEgEowMQpYLkEaIMEH/4GRWEDagER8IbwZQkL4h13uc+ooBPXFnazsnJkz315nnenBz2psvYftj8NiZWhWEXKOIaXaN6XZkNQ8NDOE5ZVRm5hGZoSh/nZbMDqdDnpIoupr24jLDtK0QdELcIJxaEYA1fB/ia/5u3jGgqoHWHj2nAY5eLmw9hsYOVxtvEMmWyAx+tBHoTgh3HZVgelF/RGSSh66VwErzyCVC/G6vn7dYdfi/FIDgylTAGWaboWTyDklGCQO5SAuwy1BUmyk+XA9xMOEjvl64w9AejiQNEQmCmXoVGaRL04JmBM8hjdaE92hnDmQn0bRfcSS/wDGIqDZBXKXFrmyCeSGVXFptl+5BgzIxF+Ai8sbGE5bIvgsHZNV5+CWnsAlGKM8/bFJ6jUwcirznM1RZOkkw5k83qxs3r6UF69WcL9fRt+Qht54Dim7isRIgITCfxePMmOQqEuqi3t9Eh7EVPQOarhzdwhP5xZvOzy/uMLHZhvN/UM0D47R/nqG1vEpDg5P0Do6QfvLN9FbtN7ZbeHTHu3bP8KHnc/4fn55ExhB/6cixg+SJNbWJtq5qQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/dbf3fcd9a80ae2f253c83a3a93825e09/e93cc/Night-Mode%402x.webp 300w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/b0544/Night-Mode%402x.webp 600w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/68fc1/Night-Mode%402x.webp 1200w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/a2303/Night-Mode%402x.webp 1800w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/4293a/Night-Mode%402x.webp 2400w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/55654/Night-Mode%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/dbf3fcd9a80ae2f253c83a3a93825e09/aaafa/Night-Mode%402x.png 300w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/8db2a/Night-Mode%402x.png 600w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/6ca50/Night-Mode%402x.png 1200w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/4d07a/Night-Mode%402x.png 1800w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/ee4de/Night-Mode%402x.png 2400w", "/static/dbf3fcd9a80ae2f253c83a3a93825e09/9391c/Night-Mode%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/dbf3fcd9a80ae2f253c83a3a93825e09/6ca50/Night-Mode%402x.png",
              "alt": "Example of night mode that can inform theming strategy.",
              "title": "Example of night mode that can inform theming strategy.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Example of night mode that can inform theming strategy.`}</figcaption>{`
  `}</figure></p>
    <p>{`The biggest remaining gap was theming. While we had laid the groundwork for it, there wasn’t any real documentation or strategy. We decided to create “day” and “night” modes to kick the tires on one potential approach. The main lift was to define semantic colors, which we could swap out to switch between modes.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACM0lEQVQoz22TTWgTQRTHc/DmXTz2UqwfWLXBKlQPUqOCCB69WAoigiWtFPzqRW0OUvwoRYoIVkptTSGYilQEPRTxZhH0oEXb2qQmge7GZL9mZrdp/s6b7BqTdODx3pt9++PN+8+EHO7BZi4sR8CyRcUHJnPT5mCOCy6N4s1qyBODWKEAWDQZikatGSaXIA/LeQPftYKKaa++jsxy6oCGAnLfKjAtbyKX1fEllcGnpTSyGQ2abtRBuWpmEyCvMTrKmlbEj8U0fi2vIp3KyTiFbE5X3xrq64FMesZd2A7NjWYjY+bBofmq2XEZC2WmxVROtfSdCa9xhrQZLM4MWEYejlVQP+ULJvR80RelCguW65WUMApIMOqEuns1+x7PJpMYm0lj4o2Gp8kVjI1PY27uI+Y/f5XxC0zGX2IqnlR+YiqBRHJWztBG0FiocmVclEobmL5/Ftt2d6Pj4iK6bv3E4e4FbN3Vhb5EHKMjT9DctBOt4U4caj+NfQeOoXnvUXREziGTW4OQXSogzcCUwHIZmH9+Bttbe9AZ/Y2+e0s40bOCLXsuo//DO4wMP0ZLUwvaD0bQFj6FcFsEO/Yfx5GT5yVQg3BL1Q7pyFy4ePBwGBeiMUSHFjAwuir9N1zqvY3xeAIzr9/iSu9VXB8YxLWbMelj6L8xiDt3H0H/Y0gN1v0O/1NZ3SfLghBM5hyu9GVqXS5SlmpJgI3yPz1AoXDXG1UOoJW86oMnRrnj19U8Pf/ZBcC/+n1REx/j/rwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/fea7ed3e8865e5faf4f0fac1bf9d5930/e93cc/Heartwood-Tokens%402x.webp 300w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/b0544/Heartwood-Tokens%402x.webp 600w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/68fc1/Heartwood-Tokens%402x.webp 1200w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/a2303/Heartwood-Tokens%402x.webp 1800w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/4293a/Heartwood-Tokens%402x.webp 2400w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/55654/Heartwood-Tokens%402x.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/fea7ed3e8865e5faf4f0fac1bf9d5930/aaafa/Heartwood-Tokens%402x.png 300w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/8db2a/Heartwood-Tokens%402x.png 600w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/6ca50/Heartwood-Tokens%402x.png 1200w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/4d07a/Heartwood-Tokens%402x.png 1800w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/ee4de/Heartwood-Tokens%402x.png 2400w", "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/9391c/Heartwood-Tokens%402x.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fea7ed3e8865e5faf4f0fac1bf9d5930/6ca50/Heartwood-Tokens%402x.png",
              "alt": "Heartwood Tokens are available online.",
              "title": "Heartwood Tokens are available online.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Heartwood Tokens are available online.`}</figcaption>{`
  `}</figure></p>
    <p>{`Explorations in theming also led to the development of Heartwood Tokens. We broke these out into a separate project and I created a static website to share with the team. Giving tokens their own home has the added benefit of making Heartwood more scalable. This opened the door to Heartwood evolving into a more robust set of tools with a cohesive foundation.`}</p>
    <CaseStudyConclusion headline="Results" text="Heartwood has already gone a long way to make Spruce useful, usable, and beautiful. By providing a shared language, it has equipped the team at Spruce Labs to continue evolving their core product. Heartwood will continue to play a role in defining the future of brick and mortar retail." mdxType="CaseStudyConclusion" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      